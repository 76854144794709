import { FC } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import SectionMenu from '@/components/ui/section-menu';
import Breadcrumbs, { ChevronRightIcon } from '@/components/ui/breadcrumbs';
import { TabsComponent } from '@/components/ui/tabs';
import { useGetContentData } from '@/hooks/content';
import { get } from 'lodash';
import { Content } from '@/types/lecture';
import { useGetUserProgress } from '@/hooks/course';
import { useGetQuiz } from '@/hooks/quiz';

const CourseContentLectureLayout: FC = () => {
  const { id, slug, reviewId } = useParams();
  const isGradesPage = window.location.href.includes('grades')

  let courseSlug = '';
  let courseName = '';
  let sectionSlug = '';
  let sectionTitle = '';

  const contentQuery = useGetContentData(id as string);
  const quiz = get(useGetQuiz(id as string), 'data.data')
  
  const content: Content = get(contentQuery, 'data.data.content', {});

  const courseId = get(content, 'section.course._id', '');
  useGetUserProgress(courseId);

  if(isGradesPage){
    courseSlug = `${slug}/grades` 
    courseName = 'Grades' 
    sectionSlug = `${slug}/grades/${quiz?._id}` 
    sectionTitle = quiz?.name || '' as string 
  }

  if (contentQuery.isSuccess) {
    courseSlug = slug as string;
    courseName = content.section.course.title;
    sectionSlug = `${courseSlug}/section/${content.section._id ?? ''}`;
    sectionTitle = content.section.name;
  }

  const firstSection = sectionTitle.length > 0

  const thirdSection = 
    (isGradesPage && (reviewId as string || '').length > 0) || 
    Object.keys(content).length > 0 

    return (
      <>
        <div>
          <Breadcrumbs>
            <li>
              <Link
                // className={`hover:text-gray-50 dark:hover:text-gray-50 ${!firstSection && 'text-gray-50'}`}
                to={`/${courseSlug}`}
              >
                {courseName}
              </Link>
            </li>
            <li>
              {firstSection && <ChevronRightIcon className="w-4 h-4" />}
            </li>
            <li>
              <Link
                // className={`hover:text-gray-50 dark:hover:text-gray-50 ${!thirdSection && 'text-gray-50'}`}
                to={`/${sectionSlug}`}
              >
                {sectionTitle}
              </Link>
            </li>
            <li>
              {
                thirdSection ? <ChevronRightIcon className="w-4 h-4" /> : null
              }
            </li>
            <li aria-current="page" 
              // className="text-gray-50 dark:text-gray-50"
            >
              {reviewId ? 'review' : content.title}
            </li>
          </Breadcrumbs>
        </div>
        
        { 
          // If Admin allow quick edit
        }

        <div className={`${!isGradesPage && 'lg:flex gap-x-5'}`}>
          <div className={`${!isGradesPage && 'lg:w-3/4'}`}>
            <Outlet />
          </div>

          {!isGradesPage && <div className="hidden md:flex lg:w-1/4 flex-col">
            <SectionMenu title={slug} sectionTitle={sectionTitle} showProgress={true} />
          </div>}
          {!isGradesPage && <div className='md:hidden'>
            {TabsComponent(
              [
                'Course Content',
                // 'OverView'
              ],
              [
                <SectionMenu title={slug} sectionTitle={sectionTitle} showProgress={true} />,
                // <div className="mx-5 mt-2">
                //   <p className="text-black text-[24px] font-bold">
                //     {content.title}
                //   </p>
                //   <p className="text-black text-[16px] text-justify mt-2">
                //     {content.readingContent}
                //   </p>
                // </div>,
              ]
            )}
          </div>}
        </div>
      </>
    );
};

export default CourseContentLectureLayout;
