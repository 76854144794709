import { apiLegacy } from "@/lib/axios"
import { useMutation } from "react-query"

export const useJupyterRequestAccess = () => {
  return useMutation({
    mutationFn: (payload: Record<string, string | number>) => {
      return apiLegacy.post(`/lti/jupyterhub/grant-access`, payload)
    },
    onError: (err) => {
      console.log(err)
      throw new Error("Cannot connect to jupyterhub")
    }
  })
}