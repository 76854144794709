import * as React from "react"
import { Label } from "@/components/ui/label";

import { cn } from "@/lib/utils"

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string
  }

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, label, ...props }, ref) => {
    return (
      <>
        {label && (
          <Label
            htmlFor={props.name}
            className="mb-2 font-medium">
            {label}
            {props.required ? <span className="text-red-600 ml-1">*</span> : ''}
          </Label>
        )}
        <textarea
          className={cn(
            "flex min-h-[80px] w-full border-0 rounded-md bg-[#2d2e33] px-3 py-2 text-sm  placeholder:text-gray-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
          ref={ref}
          {...props}
        />
      </>
    )
  }
)
Textarea.displayName = "Textarea"

export { Textarea }
