import { useUserStore } from '@/store/user';
import { Globe, MenuIcon, SunMoon } from 'lucide-react';
import { FC } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
// import LectureModal from './lecture-modal';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Link } from 'react-router-dom';
import { toggleDarkMode } from '@/main';

const Navbar: FC = () => {
  const { i18n, t } = useTranslation()
  const { currentUser } = useUserStore();
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [codeSelected, setCodeSelected] = useState('')

  const [
    logoutT,
    ,
    userRole
  ] = [
    'logout',
    'searchCourse',
    `${currentUser?.role}`
  ].map(key => get(t('navBar'), key, ''));

  // function handleSubmit(e: FormEvent) {
  //   e.preventDefault();
  //   const params = new URLSearchParams();
  //   params.append('q', search);

  //   navigate(`/courses?${params.toString()}`);
  // }
  // const setIsOpen = (val:boolean) => {
  //   setModalIsOpen(val)
  // }

  const Localization = () => {
    return <DropdownMenu>
    <DropdownMenuTrigger className="focus:outline-0 border-0">
      <Globe size={25} className='hidden lg:block' />
      <p className='font-semibold text-gray-500 lg:hidden'>Language - {localStorage.getItem('i18nextLng')}</p>
    </DropdownMenuTrigger>
    <DropdownMenuContent className="mt-3 bg-ol-primary shadow-lg border-0 px-3 z-50">
      {[
        {
          buttonName: 'English',
          buttonCode: 'en',
          buttonFn: (code: string) => {
            // if(window.location.href.includes('/content/')){
            //   setModalIsOpen(true)
            //   setCodeSelected(code)
            //   return
            // }
            i18n.changeLanguage(code)
            localStorage.setItem('i18nextLng', code);
          }
        },
        {
          buttonName: '繁體中文 (Traditional)',
          buttonCode: 'zh',
          buttonFn: (code: string) => {
            // if(window.location.href.includes('/content/')){
            //   setModalIsOpen(true)
            //   setCodeSelected(code)
            //   return
            // }
            i18n.changeLanguage(code)
            localStorage.setItem('i18nextLng', code);
          }
        },
        {
          buttonName: '简体中文 (Simplified)',
          buttonCode: 'ch',
          buttonFn: (code: string) => {
            // if(window.location.href.includes('/content/')){
            //   setModalIsOpen(true)
            //   setCodeSelected(code)
            //   return
            // }
            i18n.changeLanguage(code)
            localStorage.setItem('i18nextLng', code);
          }
        },
      ].map((eachBtn, index) => {
        return (
          <DropdownMenuItem 
            key={index}>
              <button
              className={`
                py-3 border-0 focus:outline-0 hover:opacity-60 flex items-center gap-x-3 justify-start w-full
                ${i18n.language === eachBtn.buttonCode && 'text-ol-success'}
                `}
              onClick={() => eachBtn.buttonFn(eachBtn.buttonCode)}
              type="button"
            >
              <span className='text-white'>{eachBtn.buttonName}</span>
            </button>
          </DropdownMenuItem>
        )
      })}
    </DropdownMenuContent>
  </DropdownMenu>
  }

  const MyAccount = () => {
    return <> {currentUser && (    
      <div className="flex items-center gap-x-3">
        <div className="w-[40px] h-[40px] border-2 border-gray-400 rounded-full relative overflow-hidden">
          <Avatar className="object-cover object-center w-full h-full">
            <AvatarImage src={currentUser.profilePic} alt="profile pic" />
            <AvatarFallback className="bg-transparent">
              <img
                src="user-icon-placeholder-1.png"
                alt=""
                loading="lazy"
              />
            </AvatarFallback>
          </Avatar>
        </div>
        <div>
          <p className="font-medium leading-[17px]">{currentUser.name}</p>
          <p className="text-gray-400 font-medium text-sm capitalize">
            {userRole}
          </p>
        </div>
      </div>
    )}
    </>
  }

  const LogoutUser = () => {
    return (
      <Link
        className="font-semibold duration-200 transition-all hover:text-gray-400 border-0 w-16"
        type="button"
        to="/logout">
        {logoutT}
      </Link>
    )
  }
  

  return (
    <div className="w-full py-2 px-3 shadow-lg" style={{ backgroundColor: 'var(--bg-color)' }}>
      <div className="flex justify-between items-center">
        <div id="search-bar"></div>

        <div className='lg:hidden flex items-center ml-5'>
          <DropdownMenu>
            <DropdownMenuTrigger className='outline-none focus:outline-none focus:border-0'><MenuIcon color='#868686' size={35} /></DropdownMenuTrigger>
            <DropdownMenuContent className='bg-ol-secondary text-white border-0 p-3'>
              <DropdownMenuLabel><MyAccount /></DropdownMenuLabel>
              <DropdownMenuSeparator />
              {import.meta.env.VITE_APP_SCHOOL_ENV === "Aiforfuture" ? <></> : <DropdownMenuItem><Localization /></DropdownMenuItem>}
              <DropdownMenuItem><LogoutUser /></DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        
        <div className="hidden lg:flex items-center gap-x-10">
          <Localization />
          {import.meta.env.VITE_APP_SCHOOL_ENV === "Aiforfuture" ? <></> : <SunMoon onClick={toggleDarkMode} />}
          <MyAccount />
          <LogoutUser />
        </div>
      </div>
      {/* <LectureModal setIsOpen={setIsOpen} codeSelected={codeSelected} modalIsOpen={modalIsOpen}/> */}
    </div>
  );
};

export default Navbar;
