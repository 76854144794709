import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { QuizSubmitted } from '@/types/quiz';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

export const useQuizResponseColDef = ({ selectedUserTestRes }) => {
  const helper = createColumnHelper<QuizSubmitted>();
  const { t } = useTranslation()
  const [
    nameT,
    submitted_dateT,
    questionsT,
    userT,
    scoreT,
    statusT,
    doneT,
    InReviewT,
    viewT
  ] = [
    "name",
    "submitted_date",
    "questions",
    "user",
    "score",
    "status",
    "done",
    "InReview",
    "view"
  ].map(key => get(t('quizResponses'),key, ''))

  const columns = [
    helper.accessor(`${userT}` as 'user', {
      cell: (info) => <p>{info.row.original.user.username}</p>,
    }),
    helper.accessor(`${nameT}` as 'quiz.name', {
      cell: (info) => info.row.original.quiz.name
    }),
    helper.accessor(`${submitted_dateT}` as 'submitted_date', {
      cell: (info) => 
        dayjs(new Date(info.row.original.submitted_date)).format('MMM DD, YYYY hh:mm A'),
    }),
    helper.accessor(`${questionsT}` as 'quiz.questions', {
      cell: (info) =>  
      <p>{info.row.original.quiz?.questions?.length || 1}</p>,
    }),
    helper.accessor(`${scoreT}` as 'score', {
      cell: (info) => <p>{info.row.original.score || 0}</p>,
    }),
    helper.accessor(`${statusT}` as 'review', {
      cell: (info) => <p className={`${info.row.original.review && `text-ol-success`}`}>{!info.row.original.review ? doneT : InReviewT}</p>
    }),
    helper.display({
      id: 'actions',
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-x-2 justify-end ">
            <span className='cursor-pointer hover:text-ol-info' onClick={() => selectedUserTestRes(row.original)}>{viewT}</span> 
          </div>
        );
      },
    }),
  ];

  return {
    columns,
  };
};
