import { useToast } from "@/components/ui/use-toast"
import { api } from "@/lib/axios"
import { CategoryDTO } from "@/types/category"
import i18next from "i18next"
import { get } from "lodash"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"

export const useGetCategories = (params = {}) => {
  return useQuery({
    queryKey: ['categories', params, i18next.language],
    queryFn: () => api.get('/category', { params })
  })
}

export const useGetCategoryData = (id: string | null) => {
  return useQuery({
    enabled: Boolean(id),
    queryKey: ['category', id, i18next.language],
    queryFn: () => api.get(`/category/${id}`)
  })
}

export const useCreateCategory = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation()
  const [
    categorySuccessfullyCreatedT
  ] = [
    'categorySuccessfullyCreated',
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (payload: CategoryDTO) => api.post('/category', payload),
    onSuccess: () => {
      queryClient.invalidateQueries(['categories'])
      toast({
        title: `${categorySuccessfullyCreatedT}`
      })
    }
  })
}

export const useUpdateCategory = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation()
  const [
    categorySuccessfullyUpdatedT
  ] = [
    'categorySuccessfullyUpdated',
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (payload: {
      id: string,
      data: CategoryDTO
    }) => api.put(`/category/${payload.id}`, payload.data),
    onSuccess: () => {
      queryClient.invalidateQueries(['categories'])
      toast({
        title: `${categorySuccessfullyUpdatedT}`
      })
    }
  })

}
export const useDeleteCategory = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation()
  const [
    categorySuccessfullyDeletedT
  ] = [
    'categorySuccessfullyDeleted',
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (id: string) => api.delete(`/category/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['categories'])
      toast({
        title: `${categorySuccessfullyDeletedT}`
      })
    }
  })
}