import { FC, lazy, useEffect, useMemo } from 'react';
import {
  Navigate,
  Outlet,
  // Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from './components/ui/toaster';

import LoginPage from '@/pages/login';
import LecturePage from '@/pages/lecture';
import CourseResultPage from '@/pages/course-result';

import AppLayout from '@/layouts/app-layout';
import CourseContentLayout from '@/layouts/course-content-layout';
import CourseLayout from '@/layouts/course-layout';
import AdminCurriculum from '@/layouts/admin-curriculum';
import AdminSubCurriculum from './layouts/admin-sub-curriculum';
import QuizResponsesPage from './pages/admin/quiz-responses';
import HomePageLayout from './layouts/homepage-layout';
import ProtectedRoute from './components/protected-route';
import EnrolledCoursesResultPage from './pages/enrolled-courses-result';
import FeaturedCoursesResultsPage from './pages/featured-courses-result';
import ToolPage from './pages/admin/aitoolpage';
import GoogleSSO from './pages/google-sso';
import MicrosoftSSO from './pages/microsoft-sso';
import LogoutPage from './pages/logout';
import { isEmpty } from 'lodash';
import * as Sentry from '@sentry/react';
import Uploader from './components/uploader';
import PHProvider from './PHProvider.tsx'
import { Worker } from '@react-pdf-viewer/core';

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_URL,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^http:\/\/localhost:5000\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.VITE_APP_SENTRY_ENV ?? "unknown"
});

const QuestionnairePage = lazy(() => import('@/pages/admin/questionnaire'));
const QuestionnaireUserResponse = lazy(() => import('@/pages/admin/questionnaire-user-response'));
const PublicQuestionnairePage = lazy(() => import('@/pages/public-questionnaire'));
const ManageQuestionnairePage = lazy(() => import('@/pages/admin/manage-questionnaire'))
const QuestionnaireRecipientsPage = lazy(() => import('@/pages/admin/questionnaire-recipients'))
const QuestionnaireBuilderPage = lazy(() => import('@/pages/admin/questionnaire-builder'))
const QuestionnaireResponsesPage = lazy(() => import('@/pages/admin/questionnaire-responses'))
const QuizPage = lazy(() => import('@/pages/admin/quiz'));
const AssignmentPage = lazy(() => import('@/pages/admin/assignment'));
const VerifyPage = lazy(() => import('@/pages/verify'));
const CategoryPage = lazy(() => import('@/pages/admin/category'));
const LevelPage = lazy(() => import('@/pages/admin/level'));
const CoursesListPage = lazy(() => import('@/pages/admin/course-list'));
const ManageCoursesPage = lazy(() => import('@/pages/admin/manage-courses'));
const ManageContentsPage = lazy(() => import('@/pages/admin/manage-contents'));
const CommentsPage = lazy(() => import('@/pages/admin/comments'));
const AitoolsPage = lazy(() => import ('@/pages/admin/aitools'));

const CourseGradesPage = lazy(() => import('@/pages/course-grades'));
const CourseQuizResultPage = lazy(() => import('@/pages/course-quiz-result'));
const CourseGradesReview = lazy(() => import('@/pages/course-quiz-review'));
const CourseInfoPage = lazy(() => import('@/pages/course-info'));
const CourseSectionPage = lazy(() => import('@/pages/course-section'));
const MediaLibraryPage = lazy(() => import('@/pages/admin/media-library'));
const CourseQuizPage = lazy(() => import('@/pages/course-quiz'));

const PageNotFound = lazy(() => import('@/pages/static/page-not-found'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="verify" element={<VerifyPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="logout" element={<LogoutPage />} />
      <Route path="q/public" element={<PublicQuestionnairePage />} />
      <Route path="auth/google/callback" element={<GoogleSSO />} />
      <Route path="auth/microsoft/callback" element={<MicrosoftSSO />} />

      {/* playground */}
      <Route path="pg" element={(
        <div className='p-10'>
          <Uploader 
            isOpen
            closeModal={() => {}}
          />
        </div>
      )} />

      <Route path="/" element={
        <ProtectedRoute>
          <AppLayout />
        </ProtectedRoute>
      }>
        <Route path=":slug">
          <Route element={<CourseContentLayout />}>
            <Route path="content/:id" element={<LecturePage />} />
          </Route>
          <Route element={<CourseLayout />}>
            <Route
              path="section/:id"
              element={<CourseSectionPage />}
              handle={{ selected: 'section' }}
            />
            <Route element={<CourseContentLayout />}>
              <Route
                path="grades"
                element={<CourseGradesPage />}
                handle={{ selected: 'grades' }}
              />
              <Route
                path="grades/:id"
                element={<CourseQuizResultPage />}
                handle={{ selected: 'grades' }}
              />
              <Route
                path="grades/:id/review/:reviewId"
                element={<CourseGradesReview />}
                handle={{ selected: 'grades' }}
              />
            </Route>
            <Route
              path="info"
              element={<CourseInfoPage />}
              handle={{ selected: 'info' }}
            />
            <Route
              path="quiz"
              element={<CourseQuizPage />}
              handle={{ selected: 'quiz' }}
            />
            <Route index element={<CourseInfoPage />} />
          </Route>
        </Route>
        <Route path="aitools" element={<AitoolsPage/>} />
        <Route path="aitools/:name" element={<ToolPage />} />

        <Route path='/' element={<HomePageLayout />}>
          <Route path='/' element={<CourseResultPage />} />
          <Route path='/enrolled' element={<EnrolledCoursesResultPage />} />
          <Route path='/featured' element={<FeaturedCoursesResultsPage />} />
        </Route>
        <Route path="courses" element={<Navigate to="/" replace />} />
        <Route path="enrolled" element={<CourseResultPage />} />

        <Route path="info">
          <Route path=":slug" element={<CourseInfoPage />} />
        </Route>

        <Route path="admin" element={<Outlet />}>
          <Route index element={<>Admin Page</>} />
          <Route path="questionnaire" element={<QuestionnairePage />} />
          <Route path="courses" element={<CoursesListPage />} />
          <Route path="media-library" element={<MediaLibraryPage />} />

          <Route path="questionnaire" element={<Outlet />} >
            <Route index element={<QuestionnairePage/> } />
            <Route path=":id/manage" element={<ManageQuestionnairePage/> }>
              <Route path="recipients" element={<QuestionnaireRecipientsPage/> }/>
              <Route path="builder" element={<QuestionnaireBuilderPage/> }/>
              <Route path="responses" element={<Outlet/> }>
                <Route index element={<QuestionnaireResponsesPage />} />
                <Route path="user" element={<QuestionnaireUserResponse />} />
              </Route>
            </Route>
          </Route>

          <Route path="course">
            <Route path=":slug" element={<Outlet />}>
              <Route element={<AdminCurriculum />}>
                <Route path="sections" element={<ManageCoursesPage />} />
                <Route element={<AdminSubCurriculum />}>
                  <Route path="quizzes" element={<QuizPage />} />
                  <Route path="quizzes/responses" element={<QuizResponsesPage />} />
                </Route>
                <Route path="assignments" element={<AssignmentPage />} />
                <Route path="comments" element={<CommentsPage />} />
              </Route>

              <Route path="lecture" element={<Outlet />}>
                <Route
                  path=":lecture_id/content"
                  element={<ManageContentsPage />}
                />
              </Route>
            </Route>
          </Route>
          <Route path="category" element={<CategoryPage />} />
          <Route path="level" element={<LevelPage />} />
        </Route>
      </Route>
      <Route path="404" element={<PageNotFound/>} />
    </Route>
  )
);

const FavIconMap: Record<string, string> = {
  'Aiforfuture': '/aiforfuture_icon_white.ico',
}

const App: FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  const schoolEnv = import.meta.env.VITE_APP_SCHOOL_ENV

  const favIconLogo = useMemo(() => {
    const data = FavIconMap[schoolEnv];

    return isEmpty(data) ? '/favicon.ico' : data
  }, [schoolEnv])

  // Function to change the favicon
  function changeFavicon(iconURL: string) {
    let linkElement = document.querySelector("link[rel*='icon']") as HTMLLinkElement | null;

    if (!linkElement) {
        linkElement = document.createElement('link');
        linkElement.rel = 'shortcut icon';
        document.getElementsByTagName('head')[0].appendChild(linkElement);
    }

    linkElement.type = 'image/x-icon';
    linkElement.href = iconURL;
  }
  changeFavicon(favIconLogo);

  useEffect(() => {
    const root = document.documentElement;
    const savedTheme = localStorage.getItem('kep-ol-theme');

    if (savedTheme) {
      root.classList.toggle('dark', savedTheme === 'dark');
    } else {
      root.classList.remove('dark');
    }
  }, [])

  return (
    <PHProvider>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router}/>
          <Toaster />
        </QueryClientProvider>
      </Worker>
    </PHProvider>
  );
};

export default App;
