import { BookCopy, BookOpenCheck, MessagesSquare, NotebookPen, } from 'lucide-react'
import { FC } from 'react'
import { NavLink, Outlet, useParams } from 'react-router-dom'
import cx from 'classnames'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

const AdminCurriculum:FC = () => {
  const params = useParams()

  const { t } = useTranslation()
  const [
    sectionsT,
    quizzesT,
    assignmentsT,
  ] = [
    'sections',
    'quizzes',
    'assignments',
  ].map(key => get(t('adminCurriculum'), key, ''))

  return (
    <div className="">
      <div className="flex items-center justify-start space-x-3 border-b border-ol-default py-2">
        <NavLink 
          className={({ isActive}) => cx("border rounded-md border-ol-default px-3 py-1 flex items-center gap-x-2", {
            'bg-ol-green': isActive
          })}
          to={`/admin/course/${params.slug}/sections`}>
          <BookCopy size={20} />
          <span className="font-semibold">{sectionsT}</span>
        </NavLink>
        <NavLink 
          className={({ isActive}) => cx("border rounded-md border-ol-default px-3 py-1 flex items-center gap-x-2", {
            'bg-ol-green': isActive
          })}
          to={`/admin/course/${params.slug}/quizzes`}>
          <NotebookPen size={20} />
          <span className="font-semibold">{quizzesT}</span>
        </NavLink>
        <NavLink 
          className={({ isActive}) => cx("border rounded-md border-ol-default px-3 py-1 flex items-center gap-x-2", {
            'bg-ol-green': isActive
          })}
          to={`/admin/course/${params.slug}/assignments`}>
          <BookOpenCheck size={20} />
          <span className="font-semibold">{assignmentsT}</span>
        </NavLink>
        <NavLink 
          className={({ isActive}) => cx("border rounded-md border-ol-default px-3 py-1 flex items-center gap-x-2", {
            'bg-ol-green': isActive
          })}
          to={`/admin/course/${params.slug}/comments`}>
          <MessagesSquare size={20} />
          <span className="font-semibold">Comments</span>
        </NavLink>
      </div>
      <Outlet />
    </div>
  )
}

export default AdminCurriculum