import { FC, Suspense, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Navbar from '@/components/navbar';
import { useGetCurrentUser } from '@/hooks/auth';
import SideNav from '@/components/sidenav';
import '@/lib/language-selector';
import Loading from '@/components/loading';

const AppLayout: FC = () => {
  const location = useLocation()
  const path = location.pathname

  const fullWidth = useMemo(() => {
    let fullscreen: boolean = false
    const fullWidthRoutes: RegExp[] = [
      new RegExp('^/admin/questionnaire/.+/.+')
    ]

    for (const r of fullWidthRoutes)
      if (r.test(path))
        fullscreen = true
      
    return fullscreen
  }, [path])

  useGetCurrentUser();

  return (
    <main className="flex w-screen relative">
      <div className="h-full min-h-screen fixed z-20">
        <SideNav />
      </div>
      <div className="h-16 fixed top-0 w-full pl-16 z-20">
        <Navbar />
      </div>
      <section className="flex min-h-screen flex-col overflow-hidden w-full text-white py-16 pl-16">
        <div id="content-menu"></div>
        <div 
          className={`${fullWidth ? 'w-[calc(100vw-62px)]' : 'container'} mx-auto flex-1`}>
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </div>
      </section>
    </main>
  );
};

export default AppLayout;
