import { ReactNode } from 'react';

type PropsWithOptionalChildren<P = unknown> = P & { children?: ReactNode };

export default function Breadcrumbs(props: PropsWithOptionalChildren) {
  const { children } = props;
  return (
    <nav aria-label="Breadcrumb" className="py-4">
      <ol className="flex flex-wrap gap-2 text-sm text-gray-500 dark:text-gray-400">
        {children}
      </ol>
    </nav>
  );
}

type ChevronRightIconProps<P = unknown> = P & { className?: string };

export function ChevronRightIcon(props: ChevronRightIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  );
}
