import { FC, useEffect, useState } from "react"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { Plus } from "lucide-react";
import ReactCountryFlag from "react-country-flag";
import { Ilocalization } from "../form-modals/level";
import { LocaleTabsComponent } from "../ui/tabs";
import { UseQueryResult } from "react-query";
import { LevelDTO } from "@/types/level";
import { UseFormReturn } from "react-hook-form";
import { useGetLocalization } from "@/hooks/localization";
import { makeLangDefault } from "./makeAsDefault";
import { isDefaultLang } from "./isDefault";
import { CategoryDTO } from "@/types/category";
import { OnlineCourseDTO } from "@/types/course";

export const showFlag = (code) => {
  return <ReactCountryFlag style={{fontSize: '25px'}} countryCode={code} className='mx-1' />
}

export type DTO = LevelDTO | CategoryDTO | OnlineCourseDTO

type Props = {
  isOpen: boolean;
  form: UseFormReturn<DTO>;
  formQuery: UseQueryResult
  LevelForm: (
    locale:string,
    localization: Ilocalization[],
    setLocalization: React.Dispatch<React.SetStateAction<Ilocalization[]>>,
    form: UseFormReturn<DTO>
  ) => JSX.Element
}


const flagCode = {
  'en': 'US',
  'zh': 'HK',
  'ch': 'CN',
}


export type Itranslation = {
  [key:string]: {name: string}
}


const TranslationWrapper: FC<Props> = ({
  isOpen,
  form,
  formQuery,
  LevelForm
}) => {
  const formTranslation = form.watch('translation')
  const getLocalization = useGetLocalization()
  const [localization, setLocalization] = useState<Ilocalization[]>()

  useEffect(()=> {
    if(getLocalization.isSuccess) {
      setLocalization(getLocalization.data)
      if(!form.getValues('translation')){
      getLocalization.data?.forEach(eachLang => {
        if(eachLang.default){
          const {translation, ...otherFields} = form.getValues()
          const newTranslation = Object.keys(otherFields).reduce((acc, key) => {
            if(window.location.href.includes('admin/course')){
              acc[key] = typeof form.getValues(key as never) === 'string' ? '' : [];
            }else if(typeof form.getValues(key as never) === 'string') {
              acc[key] = ''
            }
            return acc;
          }, {} as { [key: string]: string | [] });
          form.setValue('translation', {
            ...translation,
            [eachLang.code] : newTranslation
          } as never)
        }
      }) 
      }
    }
  }, [form, getLocalization])

  useEffect(() => {
    if(formQuery.isSuccess && formQuery.data && localization){
      const data = formQuery.data?.['data'] || formQuery.data
      for(const locale in data.translation){
        const firstKey = data?.name ? 'name' : 'title'
        if(
          (data[firstKey] === data.translation[locale][firstKey])
          && !isDefaultLang(locale, localization as Ilocalization[])
        ) {
          makeLangDefault(
            locale,
            localization as Ilocalization[],
            setLocalization as React.Dispatch<React.SetStateAction<Ilocalization[]>>,
            form
          )
          break
        }
      }
    }
  // eslint-disable-next-line
  }, [formQuery.data])

  useEffect(() => {
    if(!isOpen){
      getLocalization.refetch()
    }
  // eslint-disable-next-line
  }, [isOpen])

  if(getLocalization.isLoading || !formTranslation || !localization){
    return <>Loading..</>
  }

  const addTranslation = (lang) => {  
    const { translation, ...otherFields } = form.getValues();
    const newTranslation = Object.keys(otherFields).reduce((acc, key) => {
      if(window.location.href.includes('admin/course')){
        acc[key] = typeof form.getValues(key as never) === 'string' ? '' : [];
      }else if(typeof form.getValues(key as never) === 'string') {
        acc[key] = ''
      }
      return acc;
    }, {} as { [key: string]: string | [] });
    form.setValue('translation', {
      ...translation,
      [lang.code]: newTranslation
    });
  }

  const removeTranslation = (locale) => {
    if(isDefaultLang(locale, localization as Ilocalization[])){
      delete (formTranslation as Itranslation)[locale]
      makeLangDefault(
        Object.keys(formTranslation as Itranslation)[0],
        localization as Ilocalization[],
        setLocalization as React.Dispatch<React.SetStateAction<Ilocalization[]>>,
        form
      )
    }else{
      delete (formTranslation as Itranslation)[locale]
    }
    form.setValue('translation', formTranslation)
  }

  return <>
    {
      <DropdownMenu>
        <DropdownMenuTrigger className="focus:outline-0 border-0">
          <DropdownMenuLabel>
            <div className='flex justify-end'>
              <Plus className='mr-2' />
              Add Translation
            </div>
          </DropdownMenuLabel>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="bg-[#45484f] text-white shadow-lg border-0 px-4 py-2">
          {localization?.map((language, index) => (
            <DropdownMenuItem key={index}>
              <button
                className="border-0 focus:outline-0 hover:opacity-60 flex items-center gap-x-3 justify-start"
                onClick={() => addTranslation(language)}
                type="button"
              >
                <span>
                  {language.name}
                  {showFlag(flagCode[language.code])}
                  {`${language.default ? `(default)` : ''}`}
                </span>
              </button>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    }
    {
      isOpen && LocaleTabsComponent(
        Object.keys(formTranslation as Itranslation).map((locale) => {
          return {
            name: (localization?.filter(l => l.code === locale)[0] as Ilocalization).name as string,
            code: flagCode[(localization?.filter(l => l.code === locale)[0] as Ilocalization).code] as string,
            removeTranslation: () => removeTranslation(locale)
          }
        }),
        Object.keys(formTranslation as Itranslation).map((locale) => {
          return LevelForm(
            locale,
            (localization as Ilocalization[]),
            setLocalization as React.Dispatch<React.SetStateAction<Ilocalization[]>>,
            form
          )
        })
      )
    }
  </>
}
export default TranslationWrapper