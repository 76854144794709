import { useToast } from "@/components/ui/use-toast"
import { api } from "@/lib/axios"
import { LevelDTO } from "@/types/level"
import i18next from "i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"

export const useGetLevels = (params = {}) => {
  const { toast } = useToast()

  return useQuery({
    queryKey: ['levels', params, i18next.language],
    queryFn: () => api.get('/level', { params }),
    onError: (error) => {
      console.log({ error })
      toast({ title: "Something went wrong: Cannot get levels" })
    }
  })
}

export const useGetLevelData = (id: string | null) => {
  const { toast } = useToast()

  return useQuery({
    enabled: Boolean(id),
    queryKey: ['level', id, i18next.language],
    queryFn: () => api.get(`/level/${id}`),
    onError: (error) => {
      console.log({ error })
      toast({ title: "Something went wrong: Cannot get level" })
    }
  })
}

export const useCreateLevel = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  return useMutation({
    mutationFn: (payload: LevelDTO) => api.post('/level', payload),
    onSuccess: () => {
      queryClient.invalidateQueries(['levels'])
      toast({
        title: 'Level successfully created'
      })
    },
    onError: (error) => {
      console.log({ error })
      toast({ title: "Something went wrong: Cannot create level" })
    }
  })
}

export const useUpdateLevel = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  type Payload = {
    id: string,
    data: LevelDTO
  }

  return useMutation({
    mutationFn: (payload: Payload) => api.put(`/level/${payload.id}`, payload.data),
    onSuccess: () => {
      queryClient.invalidateQueries(['levels'])
      toast({ title: 'Level successfully updated' })
    },
    onError: (error) => {
      console.log({ error })
      toast({ title: "Something went wrong: Cannot update level" })
    }
  })
}

export const useDeleteLevel = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  return useMutation({
    mutationFn: (id: string) => api.delete(`/level/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['levels'])
      toast({ title: 'Level successfully deleted' })
    },
    onError: (error) => {
      console.log({ error })
      toast({ title: "Something went wrong: Cannot delete level" })
    }
  })
}