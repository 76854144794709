import { FC, ReactNode } from 'react'
import { Table, flexRender } from '@tanstack/react-table'
import { cn } from '@/lib/utils';
import { ChevronDown, ChevronUp } from 'lucide-react';

export const DataTable: FC<{
  columnSpacing?: ReactNode;
  stickyHeader?: boolean;
  isEvenColored?: boolean;
  activeRow?: string;
  canSelectRow?: boolean
  // eslint-disable-next-line
  onRowClick?: (row: Record<string, any>) => void;
  layoutFixed?: boolean;
  // eslint-disable-next-line
  table: Table<any>; 
}> = ({ table, layoutFixed, isEvenColored, onRowClick, canSelectRow, activeRow, stickyHeader }) => {
  return (
    <table
      style={layoutFixed ? { tableLayout: 'fixed' } : {}}
      className="w-full text-sm text-left text-white">
      <thead className={cn('text-xs text-gray-700 uppercase bg-[#2d2e33] dark:bg-gray-700', {
        'sticky top-0 z-[1]': stickyHeader
      })}>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              const sortBy = header.column.getIsSorted() as string
              return (
                <th
                  onClick={header.column.getToggleSortingHandler()}
                  style={{ width: header.getSize() }}
                  className={cn('py-3 px-4 text-white', {
                    'cursor-pointer': header.column.getCanSort()
                  })}
                  key={header.id}>
                  <div className="flex items-center gap-x-1">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    {sortBy === 'asc' && <ChevronUp size={12} /> }
                    {sortBy === 'desc' && <ChevronDown size={12} /> }
                  </div>
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows && table.getRowModel().rows.map(row => {
          return (
            <tr
              onClick={onRowClick ? () => onRowClick(row.original) : () => null}
              className={cn('text-white transition duration-150 hover:bg-[#36373a]', {
                'even:bg-[#383B41]': isEvenColored,
                'border-b border-[#555555]': !isEvenColored,
                'cursor-pointer': canSelectRow,
                'bg-ol-primary-500 text-white hover:bg-ol-primary-500': activeRow === row.original._id
              })}
              key={row.id}>
              {row.getVisibleCells().map(cell => {
                return (
                  <td
                    className="px-4 py-3 max-w-[320px]"
                    key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
