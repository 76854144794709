import { FC } from 'react'
import { NavLink, Outlet, useLocation, useParams } from 'react-router-dom'
import cx from 'classnames'
import { NotebookPen, MessageSquareReply } from 'lucide-react'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
// import { get } from 'lodash'
// import { useTranslation } from 'react-i18next'

let names: string[] = []
let icons: JSX.Element[] = []
let links: string[] = []

const AdminSubCurriculum:FC = () => {
  const params = useParams()
  const location = useLocation()
  const pathname = location.pathname
  const isQuiz = pathname.includes(`/admin/course/${params.slug}/quizzes`)
  
  const { t } = useTranslation()

  const [
    questionsT,
    responsesT,
  ] = [
    'questions',
    'responses',
  ].map(key => get(t('AdminSubCurriculum'), key, ''))

  if(isQuiz){
    names = [questionsT, responsesT]
    icons = [<NotebookPen size={20} />, <MessageSquareReply size={20} />]
    links = [`/admin/course/${params.slug}/quizzes`, `/admin/course/${params.slug}/quizzes/responses`]
  }

  return (
    <div className="mt-3">
      <div className="flex items-center justify-start space-x-3 border-b border-ol-default py-2">
        {names.map((eachName, index) => {
          return <NavLink 
          className={({ isActive}) => cx("border rounded-md border-ol-default px-3 py-1 flex items-center gap-x-2", {
            'bg-ol-green': isActive && pathname === links[index]
          })}
          to={links[index]}>
          {icons[index]}
          <span className="font-semibold">{eachName}</span>
        </NavLink>
        })}
      </div>
      <Outlet />
    </div>
  )
}

export default AdminSubCurriculum