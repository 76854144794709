import DefLoginIcons from '@/components/ui/def-login-icons'
import { useLogout } from '@/hooks/auth'
import { Loader2Icon } from 'lucide-react'
// import posthog from 'posthog-js'
import { FC, useEffect } from 'react'

declare global {
  interface Window {
    posthog?: {
      _i: Array<unknown>;
      init: (key: string, options: { api_host: string, person_profiles: string, capture_pageview: boolean }) => void;
      identify: (key: string, options: {email: string}) => void;
      reset: () => void;
    };
    __SV?: number;
  }
}

const LogoutPage:FC = () => {
  const logout = useLogout()

  useEffect(() => {
    window.posthog?.reset()
    logout.mutate({})
    // posthog.reset()
    //eslint-disable-next-line
  }, [])

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <DefLoginIcons />
      <div className="mt-10 flex items-center gap-x-2">
        <Loader2Icon className="animate-spin" />
        <p className="text-lg">Logging out</p>
      </div>
    </div>
  )
}

export default LogoutPage