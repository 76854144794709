import { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

function isTokenExpired(token: string) {
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('Invalid token');
  }

  const payload = JSON.parse(atob(parts[1]));
  if (!payload.exp) {
    return true;
  }

  const currentTime = Math.floor(Date.now() / 1000);  
  return payload.exp < currentTime;
}


const ProtectedRoute:FC<{ children: ReactNode }> = ({ children }) => {
  const token = localStorage.getItem('user-token')

  if (!token || isTokenExpired(token))
    return <Navigate to="/login" />

  return children
}

export default ProtectedRoute