import axios, { InternalAxiosRequestConfig } from "axios";
import i18next from "i18next";
import { isNil } from "lodash";
import 'react-query'

// declare module 'react-query' {
//   interface Register {
//     defaultError: AxiosError
//   }
// }

export const api = axios.create({
  baseURL: `${import.meta.env.VITE_APP_API_URL}/api/v2`,
  timeout: 60000
})

export const apiLegacy = axios.create({
  baseURL: `${import.meta.env.VITE_APP_API_URL}`,
  timeout: 60000
})

function insertToken(config: InternalAxiosRequestConfig) {
  const token = localStorage.getItem('user-token')
  const newConfig = config
  
  if (!isNil(token)) {
    newConfig.headers['user-token'] = token
  }
  if(!window.location.href.includes('admin')){
    newConfig.headers['lang'] = i18next.language
  }
  return newConfig
}

api.interceptors.request.use(
  (config) => insertToken(config),
  (error: Error) => {
    Promise.reject(error)
  }
)

apiLegacy.interceptors.request.use(
  (config) => insertToken(config),
  (error: Error) => {
    Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
      return response
  },
  error => {
    console.log(error)
    if (!error.response) {
        window.location.href = "/test-hello"
    }

    return Promise.reject(error)
  }
)