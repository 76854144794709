import { ChevronLeft, ChevronRight } from 'lucide-react';
import { FC, useMemo } from 'react'
import ReactPaginate from 'react-paginate'
import Loading from './loading';

export const Pagination: FC<{
  pageCount: number;
  tableIndex: number;
  pageIndex: number;
  totalItems?: number;
  loading?: boolean;
  handlePageClick: (selectedItem: { selected: number }) => void;
}> = ({ tableIndex, loading, handlePageClick, totalItems = 0, pageCount = 1, pageIndex }) => {

  const limit: number = 10
  const isLast = tableIndex + 1 === Math.ceil(totalItems / limit)

  function definePaginationLabel() {
    if (totalItems > 0) {
      return (tableIndex + 1) === 1 && !isLast
        ? `${tableIndex + 1}-${limit}`
        : isLast
          ? `${pageIndex * limit + 1} - ${totalItems}`
          : `${(pageIndex * limit + 1)}-${((tableIndex + 1) * limit)}`
    } else {
      return '0'
    }
  }

  const pageTotal = useMemo(() => {
    return pageCount ?? 1
  }, [pageCount])

  const forcePage = useMemo(() => {
    return pageIndex ?? 0
  }, [pageIndex])

  return (
    <div className="w-full py-2 flex items-center justify-between">
      <div className="flex items-center justify-start gap-x-3">
        <p className="text-sm">Showing{' '}
          <span className="font-bold">
            {definePaginationLabel()}
          </span>{' '}
          of{' '}
          <span className="font-bold">{totalItems}</span>
        </p>
        {loading ? <Loading /> : null}
      </div>

      <ReactPaginate
        breakLabel="..."
        marginPagesDisplayed={2}
        forcePage={forcePage}
        activeClassName='bg-slate-600 text-white hover:text-white'
        disabledClassName='opacity-50 pointer-events-none'
        disabledLinkClassName='opacity-50 pointer-events-none'
        pageClassName='border-[#555555] text-white hover:text-gray-200 border-y border-r p-2 min-w-[40px] flex justify-center font-medium text-sm duration-150 transition hover:bg-ol-primary-50'
        nextClassName='border-[#555555] border-r border-y px-3 py-2 rounded-r-md'
        previousClassName='border border-[#555555] px-3 py-2 rounded-l-md'
        breakClassName='border-[#555555] border-y border-r min-w-[40px] py-1.5 px-3'
        containerClassName='flex items-center justify-end'
        nextLabel={<ChevronRight className="h-5 w-5" />}
        previousLabel={<ChevronLeft className="h-5 w-5" />}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        pageCount={pageTotal}
        renderOnZeroPageCount={null}
      />
    </div>
  )
}
