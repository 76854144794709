import { omit } from "lodash";
import { DTO } from ".";
import { Ilocalization } from "../form-modals/level";
import { UseFormReturn } from "react-hook-form";

export const makeLangDefault = (
  locale: string, 
  localization: Ilocalization[], 
  setLocalization: React.Dispatch<React.SetStateAction<Ilocalization[]>>, 
  form: UseFormReturn<DTO>,
  editMode?: string
) => {
  const otherFields = omit(form.getValues(), "translation");
  const defaultLocale = localization.filter(l => l.default)[0].code
  if(!editMode){
    Object.keys(otherFields).forEach(key => {
      if(form.getValues(`translation.${defaultLocale}`) && key in form.getValues(`translation.${defaultLocale}`)){
        const formFieldValue = otherFields[key];
        const translationFieldValue = form.getValues(`translation.${defaultLocale}.${key}`);
        if (formFieldValue !== translationFieldValue) {
          form.setValue(`translation.${defaultLocale}.${key}`, formFieldValue as never);
        }
      }
    });
  }
  const updatedLocalization = localization.map(lang => {
    if ('default' in lang) {
      delete lang.default;
      Object.keys(otherFields).forEach(key => {
        if (form.getValues(`translation.${defaultLocale}`) && key in form.getValues(`translation.${lang.code}`)) {
          const translationFieldValue = form.getValues(key as never);
          form.setValue(`translation.${lang.code}.${key}` as keyof typeof otherFields, translationFieldValue as never);
        }
      });
      Object.keys(otherFields).forEach(key => {
        if(form.getValues(`translation.${defaultLocale}`) && key in form.getValues(`translation.${lang.code}`)){
          const translationFieldValue = form.getValues(`translation.${locale}.${key}`);
          form.setValue(key as keyof typeof otherFields, translationFieldValue as never);
        }
      });
    }
    if (lang.code === locale) {
      lang.default = true;
    }
    return lang;
  });
  setLocalization([...updatedLocalization]);
};
