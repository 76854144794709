import { useToast } from "@/components/ui/use-toast"
import { api } from "@/lib/axios"
import { Feedback, FeedbackReact, ReactContentPayload } from "@/types/feedback"
import { get } from "lodash"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"

export const useGetFeedbackBySlug = (slug: string | undefined) => {
  return useQuery({
    queryKey: ['feedback-slug', slug],
    enabled: Boolean(slug),
    queryFn: () => api.get(`/feedback/${slug}/get-feedback`)
  })
}

export const useGetFeedbackById = (id: string | undefined) => {
  return useQuery({
    queryKey: ['feedback-id', id],
    enabled: Boolean(id),
    queryFn: () => api.get(`/feedback/${id}`)
  })
}

export const useCreateFeedback = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    feedbackCreatedT
  ] = [
    'feedbackCreated'
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (payload: Feedback) => api.post('/feedback', payload),
    onSuccess: () => {
      queryClient.invalidateQueries(['feedbacks'])
      toast({
        title: feedbackCreatedT
      })
    }
  })
}

export const useReplyFeedback = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    feedbackRepliedT
  ] = [
    'feedbackReplied'
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (payload: Feedback) => api.post(`/feedback/${payload.content}/reply`, payload),
    onSuccess: () => {
      queryClient.invalidateQueries(['feedbacks'])
      toast({
        title: feedbackRepliedT
      })
    }
  })
}

export const useReactToContent = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: ReactContentPayload) => api.post(`/lecture/content/${payload.id}/react`, {
      type: payload.type,
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: 'content' })
    }
  })
}

export const useReactFeedback = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    feedbackReactUpdatedT
  ] = [
    'feedbackReactUpdated'
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (payload: FeedbackReact) => api.post(`/feedback/${payload.content}/react`, payload),
    onSuccess: () => {
      queryClient.invalidateQueries(['feedbacks'])
      toast({
        title: feedbackReactUpdatedT
      })
    }
  })
}

export const useUpdateFeedback = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    feedbackUpdatedT
  ] = [
    'feedbackUpdated'
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (payload: Feedback) => api.put(`/feedback/${payload.content}`, payload),
    onSuccess: () => {
      queryClient.invalidateQueries(['feedbacks'])
      queryClient.invalidateQueries(['feedback-slug'])
      toast({
        title: feedbackUpdatedT
      })
    },
  })
}

export const useGetFeedbacks = (contentId?: string) => {
  return useQuery({
    queryKey: ['feedbacks', contentId],
    queryFn: () => contentId ? api.get(`/feedback?id=${contentId}`) : null
  })
}

export const useDeleteFeedback = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    feedbackDeletedT
  ] = [
    'feedbackDeleted'
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (id: string) => api.delete(`/feedback/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['feedbacks'])
      queryClient.invalidateQueries(['feedback-slug'])
      toast({
        title: feedbackDeletedT
      })
    }
  })
}