import { FC, useEffect, useMemo, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useUserStore } from '@/store/user';
import { get } from 'lodash';
import { Category } from '@/types/category';
import CourseTypeToggle from '@/components/ui/course-type-toggle';
import { Level } from '@/types/level';
import { useGetCourses } from '@/hooks/course';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CourseEnrolledProgressCard } from '@/components/course/enrolled-progress-card';
import Loading from '@/components/loading';
import i18next from 'i18next';
import { createPortal } from 'react-dom';
import { useDebounce } from '@/hooks/debounce';
import SearchBar from '@/components/search-bar';
import { Loader2 } from 'lucide-react';
import TagFilterSelector from '@/components/tag-filter-selector';

const EnrolledCoursesResultPage: FC = () => {
  const posthogEvents = usePostHog()
  const { currentUser } = useUserStore();
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState<string>('');
  const lang = i18next.language

  const [domReady, setDomReady] = useState(false)
  const [selectedLevels, setSelectedLevels] = useState<Level[]>([])
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([])
  const [cardType, setCardType] = useState('full')

  useEffect(() => {
    setSearchParams({cardVal: cardType})
  },[cardType, setSearchParams])

  useEffect(() => {
    if(currentUser){
      posthogEvents.identify(currentUser.email)
    }
  }, [currentUser, posthogEvents])

  useEffect(() => {
    setDomReady(true)
  }, [])

  const { t } = useTranslation()

  const debouncedSearch = useDebounce(search, 300)
  const filterParams = useMemo(() => {
    return {
      search: debouncedSearch,
      fullList: true,
      lang,
      levels: selectedLevels.map((lvl) => lvl.name).join(','),
      categories: selectedCategories.map((car) => car.name).join(','),
      type: "enrolled"
    };
  }, [debouncedSearch, selectedLevels, selectedCategories, lang]);

  const coursesQuery = useGetCourses(filterParams);
  const courses = get(coursesQuery, 'data.data.data', [])

  const [
    noCoursesEnrolledT,
    coursesFoundT,
  ] = [
      'noCoursesEnrolled',
      'coursesFound',
    ].map((key) => get(t('enrolledCoursesPage'), key, ''));
    
  const [
    enrolledCoursesT
  ] = [
    'enrolledCourses',
  ].map((key) => get(t('courseResult'), key, ''))

  const searchPortal = useMemo(() => {
    if (domReady) {
      return createPortal(
        <SearchBar setSearch={setSearch} />, 
        document.querySelector('#search-bar') as Element)
    }
  }, [domReady])

  const typeCard = searchParams.get('cardVal')

  if(!typeCard) {
    return <Loading />
  }

  return (
    <main className="mt-5 container px-0 mx-auto">
      {searchPortal}
      <div className="lg:grid grid-cols-12 items-center pb-10 lg:py-10">
        <div className="col-span-3">
          <p className="font-bold text-2xl lg:text-4xl">{enrolledCoursesT}</p>
        </div>

        <div className="col-span-8 mt-5 lg:mt-0">
          <TagFilterSelector 
            selectedCategories={selectedCategories}
            selectedLevels={selectedLevels}
            setSelectedCategories={setSelectedCategories}
            setSelectedLevels={setSelectedLevels}
          />
        </div>

        <div className="col-span-1 mt-3 lg:mt-0 hidden sm:block">
          <div className="flex justify-end">
            <CourseTypeToggle cardType={cardType} setCardType={setCardType} />
          </div>
        </div>
      </div>

      {coursesQuery.isLoading ? (
        <div className="flex items-center justify-center gap-x-2">
          <Loader2 className='animate-spin' />
          <p>Loading</p>
        </div>
      )  : null}

      {coursesQuery.isSuccess && courses.length > 0 && <p className="mb-2 text-sm font-medium">{courses.length} {coursesFoundT}</p> }
      <div className={`flex flex-row flex-wrap gap-4 ${typeCard !== "full" ? 'xl:w-5/6': null }`}>
        {courses.map((course) => (
          <CourseEnrolledProgressCard
            key={course._id}
            data={course}
            cardType={typeCard}/>
        ))}
        {courses.length <= 0 && !coursesQuery.isLoading && <div>{noCoursesEnrolledT}</div>}
      </div>

    </main>
  );
};

export default EnrolledCoursesResultPage;
