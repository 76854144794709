import { useToast } from "@/components/ui/use-toast"
import { apiLegacy } from "@/lib/axios"
import axios from "axios"
import { useMutation, useQuery, useQueryClient } from "react-query"

export const useUploadFile = () => {
  const { toast } = useToast()

  return useMutation({
    mutationFn: (formData: FormData) => axios.post(`${import.meta.env.VITE_APP_API_URL}/file/upload`, formData),
    onError: () => {
      toast({ title: 'Cannot upload file. Try again later' })
    },
  })
}

export const useGetFiles = (params: Record<string, string | number> = {}) => {
  const { toast } = useToast()

  return useQuery({
    queryKey: ['files', params],
    queryFn: () => apiLegacy.get(`${import.meta.env.VITE_APP_API_URL}/file`, {
      params,
    }),
    onError: () => {
      toast({ title: 'Cannot get files. Try again later' })
    },
  })
}

export const useDownloadFile = () => {
  const { toast } = useToast()

  type Payload = {
    ids: string[]
    name?: string
  }
  return useMutation({
    mutationFn: (payload: Payload) => apiLegacy.post(`/file/download`, payload, {
      responseType: 'blob'
    }),
    onSuccess: ({ data }) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });

      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `sample.zip`;

      // Simulate a click on the link to initiate the download
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

    },
    onError: () => {
      toast({ title: 'Cannot download file. Try again later' })
    },
  })
}

export const useArchiveFile = () => {
  const { toast } = useToast()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id: string) => axios.delete(`${import.meta.env.VITE_APP_API_URL}/file/${id}/archive`),
    onSuccess: () => {
      toast({
        title: 'File successfully archived'
      })
      queryClient.invalidateQueries({ queryKey: ['files' ]})
    },
    onError: () => {
      toast({ title: 'Cannot delete file. Try again later' })
    },
  })
}