import { useToast } from "@/components/ui/use-toast"
import { api } from "@/lib/axios"
import { AssignmentDTO } from "@/types/assignment"
import { get } from "lodash"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"

export const useGetAssignmentByCourse = (params: {
  page: number,
  slug: string,
  search?: string
  fullList?: boolean
}) => {
  return useQuery({
    enabled: Boolean(params.slug),
    queryKey: ['assignments', params],
    queryFn: () => api.get(`/assignment`, {
      params
    })
  })
}

export const useGetAssignmentSubmissions = (slug: string, id: string | null) => {
  return useQuery({
    enabled: Boolean(id),
    queryKey: ['assignment-submissions', id, slug],
    queryFn: () => api.get(`/assignment/${id}/submissions`, {
      params: { slug }
    })
  })
}

export const useGetAssignmentSummary = (id: string) => {
  return useQuery({
    enabled: Boolean(id),
    queryKey: ['assignment', id],
    queryFn: () => api.get(`/assignment/${id}/summary`)
  })
}

export const useSubmitAssignment = () => {
  type Payload = {
    id: string,
    files: string[]
  }
  
  return useMutation({
    mutationFn: (payload: Payload) => api.post(`/assignment/${payload.id}/submit`, {
      files: payload.files
    })
  })
}

export const useCreateAssignment = () => {
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [
    assignmentCreatedT,
  ] = [
    'assignmentCreated',
  ].map(key => get(t('toast'), key, ''))
  
  return useMutation({
    mutationFn: (payload: AssignmentDTO) => api.post('/assignment', payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['assignments'] })
      toast({
        title: `${assignmentCreatedT}`
      })
    }
  })
}

export const useUpdateAssignment = () => {
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [
    assignmentUpdatedT,
  ] = [
    'assignmentUpdated',
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (payload: {
      id: string,
      data: AssignmentDTO
    }) => api.put(`/assignment/${payload.id}`, payload.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['assignments'] })
      toast({
        title: `${assignmentUpdatedT}`
      })
    }
  })
}

export const useDeleteAssignment = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation()
  const [
    assignmentSuccessfullyDeletedT,
  ] = [
    'assignmentSuccessfullyDeleted',
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (id: string) => api.delete(`/assignment/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['assignments'] })
      toast({
        title: `${assignmentSuccessfullyDeletedT}`
      })
    }
  })
}

export const useSaveGrade = () => {
  type Payload = {
    submissionId: string
    grade: number
  }

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: Payload) => api.post('/assignment/submission/grade', payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['assignment-submissions' ]})
    }
  })
}