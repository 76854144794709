import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';


const ToolPage:FC = () => {
  const params = useParams()
  const toolName = params.name;
  
  const { t } = useTranslation()
  const [title, setTitle] = useState<string>("");

  const [
    aiToolsT
  ] = [
    'aiTools'
  ].map((key) => get(t('aiTools'), key, ''));

  

  useEffect(() => {
    switch(toolName){
      case 'imagegenerator-realistic': 
        setTitle("Image Generator - Realistic");
      break;
      case 'imagegenerator-anime': 
        setTitle("Image Generator - Anime");
      break;
      case 'krychat': 
        setTitle("Krystal Chat");
      break;
      default:
        setTitle(toolName || "");
      break;
    }
  }, [toolName])

  const getSoundGeneratorURL = () => {
    const soundGeneratorURLs = ["https://audiocraft41.kryedu.org/", "https://audiocraft42.kryedu.org/", "https://audiocraft43.kryedu.org/", "https://audiocraft44.kryedu.org/"];
    const index = Math.floor(Math.random() * soundGeneratorURLs.length);
    return soundGeneratorURLs[index]
  }
  const getRealisticImageGeneratorURL = () => {
    const realisticImageGeneratorURLs = ["https://realistic-kep.kryedu.org/?KrystalTechnology=en"]
    // "https://realistic2-kep.kryedu.org?KrystalTechnology=en", "https://realistic3-kep.kryedu.org?KrystalTechnology=en"
    const index = Math.floor(Math.random() * realisticImageGeneratorURLs.length);
    return realisticImageGeneratorURLs[index]
  }
  const getAnimeImageGeneratorURL = () => {
    const animeImageGeneratorURLs = ["https://anime-kep.kryedu.org/?KrystalTechnology=en"]
    // "https://anime2-kep.kryedu.org?KrystalTechnology=en", "https://anime3-kep.kryedu.org?KrystalTechnology=en"
    const index = Math.floor(Math.random() * animeImageGeneratorURLs.length);
    return animeImageGeneratorURLs[index]
  }
  
  const isGetDisplay = (toolName) => {
    switch(toolName){
      case 'imagegenerator-realistic': return <iframe className={"w-4/5 my-5 mx-auto h-[800px]"} src={getRealisticImageGeneratorURL()} />;
      case 'imagegenerator-anime': return <iframe className={"w-4/5 my-5 mx-auto h-[800px]"} src={getAnimeImageGeneratorURL()} />;
      case 'krychat': return <iframe className={"w-4/5 my-5 mx-auto h-[800px]"} src="https://aihelper-kep.kryedu.org/?KrystalTechnology=en" />;
      case 'tts': return <iframe className={"w-4/5 my-5 mx-auto h-[800px]"} src="https://speech-kep.kryedu.org/?KrystalTechnology=en" />;
      case 'voiceclone': return <iframe className={"w-4/5 my-5 mx-auto h-[800px]"} src="https://voice-kep.kryedu.org/?KrystalTechnology=en" />;
      case 'soundgenerator': return <iframe className={"w-4/5 my-5 mx-auto h-[800px]"} src={getSoundGeneratorURL()} />;
      default:
        return null;
    }
  }

  const displayTool = isGetDisplay(toolName);

  return (
    <section className="h-full p-8 files-4 w-full relative">
      <span className='text-3xl text-E1E1EA font-semibold'>{aiToolsT} → {title}</span>
      <div>
        {
          displayTool
        }
      </div>
    </section>
  )
}

export default ToolPage;
