import { Section } from "@/types/section"

export const leftContentDuration = (section: Section, completedContents: string[]) => {
  let totalDurationVideo = 0
  let totalDurationReading = 0
  let totalDurationQuiz = 0
  let numOfQuizzes = 0

  if (section) {
    for (const eachLecture of section.lectures) {
      for (const eachContent of eachLecture.contents) {
        if (eachContent.duration && !completedContents.includes(eachContent._id)) {
          if (eachContent.type === 'video') {
            totalDurationVideo = totalDurationVideo + Number(eachContent.duration)
          }
          if (
            eachContent.type === 'reading' || 
            eachContent.type === 'pdf' ||
            eachContent.type === 'notebook'
          ) {
            totalDurationReading = totalDurationReading + Number(eachContent.duration)
          }
          if (eachContent.type === 'quiz' || eachContent.type === 'assignment') {
            totalDurationQuiz = totalDurationQuiz + Number(eachContent.duration)
            numOfQuizzes+=1;
          }
        }
      }
    }
  }

  const formatDuration = (duration: number): string => {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    if (hours > 0 && minutes > 0) {
      return `${hours}h ${minutes}m`;
    } else if (hours > 0) {
      return `${hours}h`;
    } else {
      return `${minutes}m`;
    }
  };

  return {
    totalDurationVideo: formatDuration(totalDurationVideo),
    totalDurationReading: formatDuration(totalDurationReading),
    totalDurationQuiz: formatDuration(totalDurationQuiz),
    totalQuizzes: numOfQuizzes,
  };
}