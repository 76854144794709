import {
  useGetAssignmentSummary,
  useSubmitAssignment,
} from '@/hooks/assignment';
import { Assignment } from '@/types/assignment';
import { Content } from '@/types/lecture';
import dayjs from 'dayjs';
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from './ui/button';
import { useUploadFile } from '@/hooks/file';
import { useToast } from './ui/use-toast';
import { Plus, XIcon } from 'lucide-react';
import { FileMetadata } from '@/types/file';
import prettyBytes from 'pretty-bytes';
import { get } from 'lodash';
import ReactQuill from 'react-quill';
import Loading from './loading';

type Props = {
  content: Content;
};

const AssignmentContent: FC<Props> = ({ content }) => {
  const { toast } = useToast();

  const assignment: Assignment | undefined = useMemo(() => {
    if (content.assignment) return content.assignment;
  }, [content]);

  const [files, setFiles] = useState<FileMetadata[]>([]);
  const summaryQuery = useGetAssignmentSummary(assignment?._id as string);
  const submitAssignment = useSubmitAssignment();
  const uploadFile = useUploadFile();
  const fileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (summaryQuery.isSuccess) {
      const data = get(summaryQuery, 'data.data');
      setFiles(get(data, 'data.submitted_files', []));
    }

    //eslint-disable-next-line
  }, [summaryQuery.data]);

  function handleUploadFile(e: ChangeEvent<HTMLInputElement>) {
    const files: FileList | null = e.target.files;

    if (files && files?.length > 0) {
      const formData = new FormData();
      const origFile = files[0]
      
      const file = new File([origFile], encodeURIComponent(origFile.name), {
        type: origFile.type,
        lastModified: origFile.lastModified
      })
      
      formData.append('file', file);

      uploadFile.mutate(formData, {
        onSuccess: ({ data }) => {
          toast({ title: 'File successfully uploaded' });
          setFiles((prev) => [...prev, data.data]);
        },
        onError: (error) => {
          toast({ title: 'Error: Upload Unsuccessful - ' + error });
        }
      });
    }
  }

  function selectFile() {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }

  function submit() {
    const confirm = window.confirm(
      'Are you sure you want to submit your assignment?'
    );

    if (confirm) {
      submitAssignment.mutate(
        {
          files: files.map((f) => f._id as string),
          id: assignment?._id as string,
        },
        {
          onSuccess: () => {
            toast({
              title: 'Assignment successfully submitted.',
            });
          },
        }
      );
    }
  }

  function removeFile(file: FileMetadata) {
    const confirm = window.confirm(
      `Are you sure you want to remove ${file.name}?`
    );
    if (confirm) {
      const list = files.filter((f) => f._id !== file._id);
      setFiles(list);
    }
  }

  if (!assignment) {
    return <Loading />;
  }

  return (
    <div>
      <input hidden ref={fileRef} onChange={handleUploadFile} type="file" />
      <p className="text-gray-50 text-[24px] font-bold">{assignment.name}</p>

      <div className="flex flex-col gap-y-5">
        <div>
          <p>
            <span className="mr-2">Due Date:</span>
            <span className="font-bold">
              {dayjs(assignment.due_date).format('YYYY-MM-DD')}
            </span>
          </p>
          <br />
          <p className="font-bold">Instruction:</p>
          <style>{`.ql-clipboard { display: none !important; }`}</style>
          <ReactQuill
            theme="bubble"
            className="bg-transparent text-white"
            defaultValue={assignment.instruction}
            readOnly={true}
            modules={{ toolbar: false }}
          />
        </div>

        <div>
          <div className="flex items-center justify-between">
            <p className="font-bold">Submission:</p>

            <button
              className="flex items-center gap-x-1"
              onClick={selectFile}
              type="button"
            >
              <Plus size={15} />
              Upload Assignment
            </button>
          </div>

          {files.length > 0 && (
            <div className="mb-5 mt-3 w-2/3 border rounded-md border-ol-tertiary">
              <div className="grid grid-cols-12 border-b p-2 border-ol-tertiary">
                <div className="col-span-5">
                  <p className="font-bold">Name</p>
                </div>
                <div className="col-span-3">
                  <p className="font-bold">Size</p>
                </div>
                <div className="col-span-3">
                  <p className="font-bold">Date Uploaded</p>
                </div>
              </div>
              {files.map((file) => (
                <div className="grid grid-cols-12 p-2">
                  <div className="col-span-5">
                    <p>{file.name}</p>
                  </div>
                  <div className="col-span-3">
                    <p>{prettyBytes(file.size)}</p>
                  </div>
                  <div className="col-span-3">
                    <p>{dayjs(file.createdAt).format('YYYY-MM-DD')}</p>
                  </div>
                  <div className="col-span-1">
                    <button onClick={() => removeFile(file)} type="button">
                      <XIcon className="text-red-500" size={15} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex justify-end">
          <Button
            disabled={submitAssignment.isLoading}
            onClick={submit}
            type="button"
          >
            Submit Assignment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AssignmentContent;
