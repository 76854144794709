import { z } from 'zod'

export const levelSchema = z.object({
  name: z.string().trim().min(1, { message: 'Category name is required'}),
  order: z.number(),
  translation: z.record(z.string(), z.object({name: z.string().trim()})).optional()
})

export type LevelDTO = z.infer<typeof levelSchema>

export type Level = Pick<LevelDTO, 'name' | 'order' | 'translation'> & {
  _id: string
  createdAt: Date
}
