import { useToast } from "@/components/ui/use-toast"
import i18next from "i18next"
import { useQuery } from "react-query"

export const useGetLocalization = (params = {}) => {
  const { toast } = useToast()

  return useQuery({
    queryKey: ['localizations', params, i18next.language],
    queryFn: () => [
      {
        name: 'English',
        code: 'en',
        default: true,
      },
      {
        name: 'Traditional Chinese',
        code: 'zh'
      },
      {
        name: 'Simplified Chinese',
        code: 'ch'
      }
    ],
    onError: (error) => {
      console.log({ error })
      toast({ title: "Something went wrong: Cannot get localizations" })
    }
  })
}