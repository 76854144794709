import { DataTable } from '@/components/data-table';
import { useDebounce } from '@/hooks/debounce';
import { ResultList } from '@/types/common';
import {
  PaginationState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { get, startCase } from 'lodash';
import { Search } from 'lucide-react';
import { Pagination } from '@/components/table-pagination';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSubmittedSessions, useUpsertSubmittedSessions } from '@/hooks/quiz';
import { QuizSubmitted } from '@/types/quiz';
import Loading from '@/components/loading';
import { useQuizResponseColDef } from '@/components/column-defs/quiz-response';
import { Dialog, DialogContent } from '@radix-ui/react-dialog';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { SelectInput } from '@/components/ui/select';

const QuizResponsesPage: FC = () => {
  const params = useParams();
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [userQuizRes, setUserQuizRes] = useState<QuizSubmitted | null>(null)
  const [search, setSearch] = useState<string>('');
  const [quizName, setQuizName] = useState<string>('all');
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageIndex, pageSize]
  );

  const searchDebounce = useDebounce(search, 300);
  const filterParams = useMemo(() => {
    return {
      search: searchDebounce,
      page: pageIndex,
      slug: params.slug,
      submittedSessions: true,
      quizName
    };
  }, [searchDebounce, params, pageIndex, quizName]);
  const getQuizzesQuery = useGetSubmittedSessions(filterParams);
  const upsertSubmmitedQuiz = useUpsertSubmittedSessions()
  const data: ResultList<QuizSubmitted> = get(getQuizzesQuery, 'data.data', []);

  const quizzes = get(data, 'data', []);
  const quizNames = get(data, 'quizNames', []);
  const metadata = get(data, 'metadata', { total: 0 });

  const { t } = useTranslation()
  const [
    quizzesResponsesT,
    searchQuizResponsesT,
    scoreT,
    ansT,
    userAnsT,
    backT,
    saveAsDraftT,
    completeMarkingT
  ] = [
    'quizzesResponses',
    'searchQuizResponses',
    "score",
    "ans",
    "userAns",
    "back",
    "saveAsDraft",
    "completeMarking",
  ].map(key => get(t('quizResponses'), key, ''))

  function handlePageClick(e: { selected: number }) {
    table.setPageIndex(e.selected);
  }

  const selectedUserTestRes = (res) => {
    setUserQuizRes(res)
    setModalIsOpen(true)
  }

  const { columns } = useQuizResponseColDef({ selectedUserTestRes });

  const table = useReactTable({
    data: quizzes,
    columns,
    manualPagination: true,
    manualSorting: true,
    pageCount: Math.ceil(metadata.total / 10),
    state: { pagination },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const onCalculateScore = useCallback(() => {
    if(userQuizRes?.submitted_data){
      let scoreCal = 0
      for(const evalData of userQuizRes.submitted_data){
        scoreCal = Number(scoreCal) + Number(evalData.scored)
      }
      setUserQuizRes({ 
        ...userQuizRes,
        score: Number(scoreCal)
       })
    }
  }, [userQuizRes])

  useEffect(() => {
    onCalculateScore()
  },[userQuizRes, onCalculateScore])

  useEffect(() => {
    if(upsertSubmmitedQuiz.isSuccess){
      setUserQuizRes(null)
      setModalIsOpen(false)
    }
  },[upsertSubmmitedQuiz.isSuccess])

  const onChangeVal = (index:number, key:string, val:string|number) => {
    if(userQuizRes?.submitted_data){
      const updatedData = [...userQuizRes.submitted_data]
      updatedData[index][key] = val
      setUserQuizRes({ 
        ...userQuizRes,
        submitted_data: updatedData
      })
    }
  }

  return (
    <main className="mt-5">

      <p className="text-3xl font-semibold">{quizzesResponsesT}</p>

      <section className="p-4 mt-3 rounded-lg shadow-2xl shadow-ol-secondary bg-[#383B41]">
        <div className="flex items-center justify-between gap-x-10 mb-5">
          <div className="w-full max-w-md flex gap-x-3 items-center justify-start bg-[#2d2e33] px-4 py-2 rounded-lg">
            <Search size={18} />
            <input
              className="bg-transparent outline-none border-0 w-full"
              placeholder={searchQuizResponsesT}
              onChange={(e) => setSearch(e.target.value)}
              type="text"
            />
          </div>

          <div className="w-[250px] text-white h-full">
            <p className="text-xs mb-1 font-medium">Quiz Filter</p>
            <SelectInput
                onChange={(val) => setQuizName(val)}
                value={quizName}
                options={['all', ...quizNames].map((q) => ({
                  label: startCase(q),
                  value: q
                }))}
              />
          </div>
        </div>

        {getQuizzesQuery.isLoading ? (
          <Loading />
        ) : (
          <>
            <DataTable table={table} />
            <Pagination
              totalItems={metadata.total}
              loading={getQuizzesQuery.isLoading}
              handlePageClick={handlePageClick}
              pageCount={table.getPageCount()}
              pageIndex={table.getState().pagination.pageIndex}
              tableIndex={table.getState().pagination.pageIndex}
            />
          </>
        )}
      </section>
      <div className={`${modalIsOpen && 'fixed inset-0 z-50 overflow-y-auto bg-opacity-80 bg-tertinary flex justify-center items-center'}`}>
        <Dialog open={modalIsOpen}>
          <DialogContent className="shadow-2xl w-full max-w-2xl border-0 bg-ol-primary p-5 rounded overflow-y-scroll max-h-[96vh]">
            <div className='flex flex-col justify-center items-center text-center'>
              <p className='text-xl font-bold'>{userQuizRes?.user.username}</p>
              <p className='mt-2'>{scoreT}: {userQuizRes?.score}/{userQuizRes?.submitted_data.reduce((acc, d) => d.ques_score + acc, 0)}</p>
              <div className='mt-5 w-full'>
                {
                  userQuizRes?.submitted_data.map((eachQA, index) => {
                    return <div className='flex mt-3' key={eachQA._id}>
                      <p className='mr-4'>{`${index + 1})`}</p>
                      <div className='w-full'>
                        <div className='flex items-center justify-between'>
                          <div className='flex flex-col items-start w-full'>
                            <p className='text-start'>{eachQA.question}</p>
                            {
                              eachQA.imagesUrl.map((eachUrl) => {
                                if(eachUrl.includes('.svg')){
                                  return <ReactSVG src={eachUrl} />
                                }
                                return <img src={eachUrl} alt='image' loading="lazy"/>
                              })
                            }
                            {eachQA.options.length > 1 && eachQA.options.map((option) => {
                              return <p className='flex mt-1'>
                                <span>{`${option.key}.`}</span>
                                <span className={`ml-1`}>{option.text}</span>
                              </p>
                            })}
                            <p className='mt-3'><span className='text-ol-success'>{ansT}:</span> {eachQA.answer}</p>
                            <div className='flex items-center w-full'>
                              <p className='mt-3 w-9/12 text-left'><span className='text-ol-info'>{userAnsT}:</span> {eachQA.user_answer}</p>
                              <p className='mx-3 w-3/12 flex items-center'>
                                {scoreT}: 
                                <input
                                  value={eachQA.scored}
                                  onChange={(e) => (Number(e.target.value) <= eachQA.ques_score && Number(e.target.value) >= 0)
                                    && onChangeVal(index, 'scored', Number(e.target.value))}
                                  className='w-full border-ol-tertinary bg-ol-primary ml-3'
                                />
                                <span className='ml-1'>/{eachQA.ques_score}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <textarea
                        value={eachQA.comment}
                        onChange={(e) => onChangeVal(index, 'comment', e.target.value)}
                        className='bg-ol-primary w-full mt-3 border-ol-tertinary px-2'
                        placeholder='comments ...' />
                      </div>
                    </div>
                  })
                }
              </div>
              <div className='flex mt-8'>
                <Button className="inline bg-ol-warning hover:bg-ol-warning"
                  onClick={() => {
                    setUserQuizRes(null)
                    setModalIsOpen(false)
                  }}
                >{backT}</Button>
                <Button className="ml-4 inline"
                  onClick={() => {
                    if(userQuizRes && userQuizRes.submitted_data){
                      upsertSubmmitedQuiz.mutate({
                        attempt_token: userQuizRes.attempt_token,
                        submitted_data: userQuizRes.submitted_data,
                        review: true,
                      })
                    }
                  }}
                >{saveAsDraftT}</Button>
                <Button className="ml-4 inline bg-ol-success hover:bg-ol-success"
                  onClick={() => {
                    if(userQuizRes && userQuizRes.submitted_data){
                      upsertSubmmitedQuiz.mutate({
                        attempt_token: userQuizRes.attempt_token,
                        submitted_data: userQuizRes.submitted_data,
                        review: false,
                      })
                    }
                  }}
                >{completeMarkingT}</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </main>
  );
};

export default QuizResponsesPage;
