import { FC, useMemo, useRef, useState } from 'react'
import { clone, get } from 'lodash'
import { useUploadFile } from '@/hooks/file'
import Queue from 'queue'
import prettyBytes from 'pretty-bytes'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { CheckCircle, Info, Loader2, Plus, Trash } from 'lucide-react'
import { Button } from './ui/button'
import { useToast } from './ui/use-toast'

type Props = {
  isOpen: boolean
  closeModal: () => void
}

const Uploader:FC<Props> = ({ isOpen, closeModal }) => {
  
  const [files, setFiles] = useState<File[]>([])
  const [uploaded, setUploaded] = useState<string[]>([])
  const [uploading, setUploading] = useState<string>('')
  const [uploadStarted, setUploadStarted] = useState<boolean>(false)
  
  const fileRef = useRef<HTMLInputElement>(null)
  const uploadFile = useUploadFile();

  const { toast } = useToast()

  const q = useMemo(() => new Queue({ 
    concurrency: 1,
    autostart: false,
    results: [] 
  }), [])

  function openSelectFile() {
    if (fileRef && fileRef.current)   
      fileRef.current.click()
  }

  function onSelectFiles(e) {
    const fileList = get(e, 'target.files', [])

    if (fileList.length > 0) {
      for (const file of fileList as FileList) {
        setFiles((prev) => ([...prev, file]))
      }
    }
  }

  function processQueue(queue) {
    const task = queue.shift(); // Get the first task in the queue
    if (task) {
      task().then(() => {
        processQueue(queue); // Continue with the next task after the current one is resolved
      });
    } else {
      console.log('All files have been uploaded.');
      setUploadStarted(false)
      setFiles([])
      setUploaded([])
      setUploading('')
      closeModal()
      toast({ title: 'Successfully uploaded files!' })
    }
  }

  function removeFile(index: number) {
    const updatedFileList = clone(files)
    updatedFileList.splice(index, 1)

    setFiles(updatedFileList) 
  }

  function startUpload() {
    for (const file of files) {
      const formData = new FormData();

      const f = new File([file], encodeURIComponent(file.name), {
        type: file.type,
        lastModified: file.lastModified
      })
      
      formData.append('file', f);

      q.push(() => {
        return Promise.all([
          new Promise((res) => {
            setUploading(file.name),
            res(true)
          }),
          uploadFile.mutateAsync(formData, {
            onSuccess: (res) => {
              const name = get(res, 'data.data.name', '')
              setUploaded((prev) => ([...prev, decodeURIComponent(name)]))
              setUploading('')
            }
          })
        ])
      })
    }

    setUploadStarted(true)
    processQueue(q)
  }

  return (
    <Dialog
      open={isOpen} 
      onOpenChange={uploading ? () => {} : closeModal}>
      <input 
        hidden
        accept='.pdf, .csv, .xlsx, .jpg, .jpeg, .webp, .png, .gif, .mp4, .mov'
        onChange={onSelectFiles}
        ref={fileRef}
        multiple
        type="file" />

      <DialogContent 
        className="w-full max-w-xl shadow-2xl shadow-tertiary bg-ol-primary rounded-lg p-4 border-0 max-h-[95vh] overflow-y-scroll no-scrollbar">
        <DialogHeader>
          <DialogTitle className="text-xl font-medium mb-2">
            Upload Files
          </DialogTitle>
          <DialogDescription className="text-gray-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          </DialogDescription>
        </DialogHeader>

        <div>
          {files && files.length > 0 ? (
            <div className='flex flex-col gap-y-2'>
              { files?.map((file, i) => {
                return (
                  <div 
                    key={i}
                    className="bg-neutral-600 rounded-md grid grid-cols-12 text-white items-center gap-x-2 p-2 text-sm">
                    <div className="col-span-6">
                      <p className="truncate">{file.name}</p>
                    </div>
                    <div className="col-span-2">
                      <p>{prettyBytes(file.size)}</p>
                    </div>
                    {uploadStarted ? (
                      <div className="col-span-4">
                        <p>{uploadStarted && uploading === file.name && <Loader2 
                          size={18}
                          className='animate-spin' />}</p>
                        <p>{uploadStarted && !uploaded.includes(file.name) && uploading !== file.name ? 'pending' : ''}</p>
                        {uploadStarted && uploaded.includes(file.name) 
                          ? <p className="text-success-500 flex items-center justify-start gap-x-2"> 
                              <CheckCircle size={18} /> 
                              Uploaded
                            </p>
                          : ''}
                      </div>
                    ) : (
                      <div className="col-span-4 flex justify-end">
                        <button 
                          onClick={() => removeFile(i)}
                          className='cursor-pointer'
                          type="button">
                          <Trash className='text-red-500' size={18} />
                        </button>
                      </div>
                    )}
                  </div>
                )
              })}  

               <button 
                disabled={uploadStarted}
                onClick={openSelectFile}
                className='text-white flex items-center gap-x-1 w-fit text-sm'
                type="button">
                <Plus size={20} />
                Add More
              </button>
            </div>
          ) : (
            <div 
              onClick={openSelectFile}
              className="rounded-md bg-neutral-700 border border-dashed flex flex-col items-center p-10 min-h-36 cursor-pointer duration-200 transition-all hover:bg-neutral-600">
              <p className="font-semibold text-lg">Click to select files</p>

              <div className="flex flex-col items-center text-sm italic mt-5">
                <div className="flex text-orange-300 gap-x-2 mb-2">
                  <Info size={20} />
                  <p className="font-medium">Please upload supported types of files:</p>
                </div>
                <p>Images - (jpeg, jpg, png, webp, gif)</p>
                <p>Document - (pdf, csv, xlsx)</p>
                <p>Media - (mp4, mov)</p>
              </div>
            </div>
          )}
        </div>

        {files.length > 0 && (
          <div className="flex justify-end">
            <Button
              disabled={uploadStarted}
              onClick={startUpload}
              className="w-fit"
              type="button">
              Start Upload
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default Uploader