import DefLoginIcons from '@/components/ui/def-login-icons';
import { useGetCurrentUser } from '@/hooks/auth';
import { apiLegacy } from '@/lib/axios';
import { Loader2Icon } from 'lucide-react';
import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { posthogSetIdentity } from '@/helper/auth-helper';

const GoogleSSO:FC = () => {
  const getCurrentUser = useGetCurrentUser(false)
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const navigate = useNavigate()

  useEffect(() => {
    async function requestAccessToken() {
      try {
        const res = await apiLegacy.post('/auth/google/request-access', { 
          type: 'online_learning',
          code
        });

        if (res) {
          localStorage.setItem('google_access_token', res.data.access_token);
          localStorage.setItem('user-token', res.data.jwt_token);
          posthogSetIdentity(res.data.jwt_token);
          getCurrentUser.refetch()
          
          navigate('/');
        }
      } catch (error) {
        console.log({ error })
      }
    }

    requestAccessToken()
    //eslint-disable-next-line
  }, [code])
  
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <DefLoginIcons />
      <div className="mt-10 flex items-center gap-x-2">
        <Loader2Icon className="animate-spin" />
        <p className="text-lg">Verifying Google Account</p>
      </div>
    </div>
  )
}

export default GoogleSSO