import {
  BookOpenText,
  FileImage,
  FileQuestion,
  LayoutGrid,
  LucideIcon,
  Gauge,
  Table2,
  BotIcon
} from 'lucide-react';
import { FC, useMemo } from 'react';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useUserStore } from '@/store/user';
import { get, isEmpty, lowerCase } from 'lodash';
import { useTranslation } from 'react-i18next';

const SideNavLogoMap: Record<string, string> = {
  'aiforfuture': '/aiforfuture_logo.png',
}


const SideNav: FC = () => {
  const schoolEnv = (import.meta.env.VITE_APP_SCHOOL_ENV ?? '').toLowerCase()

  const sideNavLogo = useMemo(() => {
    const data = SideNavLogoMap[schoolEnv];

    return isEmpty(data) ? '/krystal.png' : data
  }, [schoolEnv])

  const loc = useLocation();
  const { currentUser } = useUserStore();

  const { t } = useTranslation()
  const [
    browseCoursesT,
    aiToolsT,
    manageCoursesT,
    categoriesT,
    levelT,
    mediaLibraryT,
    questionnaireT,
  ] = [
    'browseCourses',
    'aiTools',
    'manageCourses',
    'categories',
    'level',
    'mediaLibrary',
    'questionnaire',
  ].map(keys => get(t('sideNav'), keys, ''))

  const paths: { path: string; activeOnPath?: string[], label: string; icon: LucideIcon; environment?: string }[] = [
    { path: '/courses', activeOnPath: ['/', '/courses', '/enrolled','/featured'], label: browseCoursesT, icon: BookOpenText },
    { path: '/aitools', activeOnPath: ['/aitools'], label: aiToolsT, icon: BotIcon, environment: import.meta.env.VITE_APP_SCHOOL_ENV },
  ];

  const adminPaths: { path: string; activeOnPath?: string[]; label: string; icon: LucideIcon }[] = [
    { path: '/admin/courses', label: manageCoursesT, icon: Table2 },
    { path: '/admin/category', label: categoriesT, icon: LayoutGrid },
    { path: '/admin/level', label: levelT, icon: Gauge },
    { path: '/admin/media-library', label: mediaLibraryT, icon: FileImage },
    { path: '/admin/questionnaire', label: questionnaireT, icon: FileQuestion },
  ];

  return (
    <aside className="h-full w-16 bg-white text-ol-secondary">
      <div className={`${schoolEnv === 'aiforfuture' ? 'bg-white' : 'bg-ol-success'}  h-[62px] grid place-items-center`}>
        <img 
          className="h-10 w-10"
          src={sideNavLogo}
          loading="lazy" />
      </div>
      <hr className="bg-ol-secondary mb-2" />

      <div className="flex flex-col">
        {paths.map((ap) => {
          if(ap.environment && ap.environment !== 'Aiforfuture' && ap.environment !== 'Cuhkjc-aiforfuture'){
            return <></>
          }
          return (
            <TooltipProvider key={ap.path}>
              <Tooltip>
                <Link
                  key={ap.path}
                  className={cx(
                    'border-0 duration-100 transition-all group w-full flex items-center justify-center h-[52px]',
                    {
                      'bg-ol-info text-white': ap.activeOnPath?.includes(loc.pathname),
                      'bg-white text-ol-secondary hover:text-ol-success': !ap.activeOnPath?.includes(loc.pathname),
                    }
                  )}
                  to={ap.path}
                >
                  <TooltipTrigger className="border-0 outline-0 focus:border-0 focus:outline-0">
                    <ap.icon size={30} />
                  </TooltipTrigger>
                </Link>
                <TooltipContent>
                  <p>{ap.label}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
        )})}
      </div>

      <hr className="bg-ol-secondary my-2" />

    {currentUser && lowerCase(get(currentUser, 'role', '')) !== "student"
      && lowerCase(get(currentUser, 'role', '')) !== "teacher" 
      &&
        <div className="flex flex-col">
          <p className="text-xs text-center font-bold mb-1.5 text-ol-primary">Admin</p>
          {adminPaths.map((ap) => (
            <TooltipProvider key={ap.path}>
              <Tooltip>
                <Link
                  to={ap.path}
                  className={`border-0 duration-100 transition-all group w-full flex items-center justify-center h-[52px]
                    ${loc.pathname.includes(ap.path) 
                      ? 'bg-ol-info text-white hover:text-tertiary' 
                    : 'bg-white text-ol-secondary hover:text-ol-success'}
                  `}>
                  <TooltipTrigger className="border-0 outline-0 focus:border-0 focus:outline-0">
                    <ap.icon size={30} />
                  </TooltipTrigger>
                </Link>
                <TooltipContent>
                  <p>{ap.label}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>
      }
      <div className="bottom-0">
        <p className="m-3 text-[10px] text-center text-ol-primary">{`v${APP_VERSION}`}</p>
      </div>
    </aside>
  );
};

export default SideNav;
