import * as React from "react";

import { cn } from "@/lib/utils";
import { Label } from "@/components/ui/label";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string,
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, label, type, ...props }, ref) => {

    return (
      <div className="w-full">
        {label && (
          <Label 
            htmlFor={props.name}
            className="mb-2 font-medium">
            {label}
            {props.required ? <span className="text-red-600 ml-1">*</span> : ''}
          </Label>
        )}
        <input
          id={props.name}
          type={type}
          className={cn(
            className,
            "flex h-10 border-0 w-full rounded-md bg-[#2d2e33] px-3 py-2 text-sm  placeholder:text-gray-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
          )}
          ref={ref}
          {...props}
        />
      </div>
    )
  }
)
Input.displayName = "Input"

export { Input }
