import { useUserStore } from '@/store/user';
import posthog from 'posthog-js';
import * as React from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';

export interface VideoPlayerProps {
  url: string;
  subtitle: string;
  light?: string;
  onFinish: () => void;
}

export interface VideoPlayerState {
  url?: string;
  pip: boolean;
  playing: boolean;
  controls: boolean;
  light: boolean;
  volume: number;
  muted: boolean;
  played: number;
  loaded: number;
  duration: number;
  playbackRate: number;
  loop: boolean;
  seeking: boolean;
  showCaptions: boolean;
}

const DEFAULT_STATE: VideoPlayerState = {
  url: undefined,
  pip: false,
  playing: false,
  controls: true,
  light: false,
  volume: 0.8,
  muted: false,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
  seeking: false,
  showCaptions: true,
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url: initialUrl,
  subtitle,
  onFinish
}) => {
  const [playerState, setPlayerState] =
    React.useState<VideoPlayerState>(DEFAULT_STATE);

  const [played, setPlayed] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const load = React.useCallback((url?: string) => {
    setPlayerState((prePlayerState) => ({
      ...prePlayerState,
      url,
      played: 0,
      loaded: 0,
      pip: false,
    }));
  }, []);

  React.useEffect(() => {
    if (initialUrl) {
      load(initialUrl);
    }
  }, [initialUrl, load]);

  const vttBlob = new Blob([subtitle], {type : 'text/vtt'});
  const blobURL = URL.createObjectURL(vttBlob);
  const { currentUser } = useUserStore();
  const { id, slug } = useParams();

  const captureEvent = (eventName:string, videoTime?:number) => {
    const payload = {
      email: currentUser?.email,
      contentInfo : {
        course_id: slug,
        lecture_id: id,
        video_id: initialUrl,
        user_id: currentUser?.email,
      }
    }
    if(videoTime){
      payload.contentInfo['current_time_of_video'] = videoTime
    } 
    posthog.capture(eventName, payload)
  }

  return (
    <>
      <section
        className={
          'bg-black m-12 max-w-screen-xl max-h-screen-lg mx-0 rounded-lg overflow-hidden mt-0 mb-[30px]'
        }
      >
        <div className="flex justify-center">
          <div className="aspect-video w-full h-full bg-gray-100">
            <ReactPlayer
              width="100%"
              height="100%"
              url={playerState.url}
              pip={playerState.pip}
              playing={playerState.playing}
              controls={playerState.controls}
              light={playerState.light}
              loop={playerState.loop}
              playbackRate={playerState.playbackRate}
              volume={playerState.volume}
              muted={playerState.muted}
              disablePictureInPicture={true}
              progressInterval={1000}
              onError={(e) => console.log('onError', e)}
              config={{
                file: {
                  tracks: subtitle.length > 0 ? [
                    {
                      kind: 'subtitles',
                      src: blobURL,
                      srcLang: 'en',
                      label: 'On',
                      default: true
                    },
                  ] : [],
                  attributes: {
                    controlsList: 'nodownload',
                    crossOrigin: 'true',
                  },
                },
              }}
              onStart={() => {
                // console.log("On start")
                captureEvent(`video content started`)
                setIsPlaying(true)
                }}
              onPlay={() => {
                // console.log("Resume at ", played)
                captureEvent(`video content resumed`)
                setIsPlaying(true)
              }}
              onSeek={e => {
                  // console.log("Seeking to ", Math.round(e * 100) / 100)
                  captureEvent(`video content seeking`, Math.round(e * 100) / 100)
                }
              }
              onPause={ () => {
                // console.log("Pausing at ", Math.round(played * 100) / 100)
                captureEvent(`video content paused`, Math.round(played * 100) / 100)
                setIsPlaying(false)
                }
              }
              onEnded={() => {
                // console.log("Finished Video")
                captureEvent(`video content finished`)
                onFinish()
              }}
              onProgress={(progress) => {
                if(isPlaying){
                  // console.log("playing to ", progress.playedSeconds)
                  captureEvent(`video content playing`, progress.playedSeconds)
                  setPlayed(progress.playedSeconds)
                }
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoPlayer;
