import { FC } from 'react';
import { useParams } from 'react-router-dom';
import LectureContent from '@/components/ui/lecture-content';
import { useGetContentData } from '@/hooks/content';
import { get } from 'lodash';
import { Content } from '@/types/lecture';
import Loading from '@/components/loading';

const LecturePage: FC = () => {
  const { id, slug } = useParams();

  const contentQuery = useGetContentData(id as string);
  const content: Content = get(contentQuery, 'data.data.content', {});
  const flStatus = {
    isFirstContent : get(contentQuery, 'data.data.isFirstContent', {}),
    isLastContent : get(contentQuery, 'data.data.isLastContent', {})
  }

  if (contentQuery.isSuccess) {
    return <LectureContent content={content} slug={slug} flStatus={flStatus} />;
  }

  if (contentQuery.isLoading) {
    return <Loading />;
  }

  if (contentQuery.isError || !contentQuery.isLoading) {
    return <div> Error ... </div>;
  }
};

export default LecturePage;
