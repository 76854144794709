import { useToast } from "@/components/ui/use-toast"
import { api } from "@/lib/axios"
import { useUserStore } from "@/store/user"
import { ContentDataDTO, OnlineCourseDTO } from "@/types/course"
import { get } from "lodash"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"

export const useCreateCourse = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    CourseSuccessfullyCreatedT
  ] = [
    'courseSuccessfullyCreated'
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (payload: OnlineCourseDTO) => api.post('/online-course', payload),
    onSuccess: () => {
      queryClient.invalidateQueries(['courses'])
      toast({
        title: `${CourseSuccessfullyCreatedT}`
      })
    }
  })
}

export const useUpdateCourse = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    courseSuccessfullyupdatedT
  ] = [
    'courseSuccessfullyupdated'
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (payload: {
      slug: string,
      data: OnlineCourseDTO
    }) => api.put(`/online-course/${payload.slug}`, payload.data),
    onSuccess: () => {
      queryClient.invalidateQueries(['courses'])
      toast({
        title: `${courseSuccessfullyupdatedT}`
      })
    },
  })
}

export const useGetCourses = (params: Record<string, string | number | boolean>) => {
  return useQuery({
    queryKey: ['courses', params],
    queryFn: () => api.get('/online-course', { params })
  })
}

export const useGetUserProgress = (id: string) => {
  const { setCompletedContents, setSubmittedAssignments } = useUserStore()

  return useQuery({
    enabled: Boolean(id),
    queryKey: ['course-progress', id],
    queryFn: () => api.get(`/online-course/${id}/progress`),
    onSuccess: ({ data }) => {
      const completedContents: string[] = get(data, 'data.completed_contents')
      const submittedAssignments: string[] = get(data, 'data.submittedAssignments')
      setCompletedContents(completedContents)
      setSubmittedAssignments(submittedAssignments)
    }
  })
}

export const useGetEnrolledCourses = (params: Record<string, string | number | boolean>) => {
  return useQuery({
    queryKey: ['enrolled-courses', params],
    queryFn: () => api.get('/online-course/enrolled', { params })
  })
}

export const useGetCourseData = (slug: string | undefined | null) => {
  return useQuery({
    queryKey: ['course', slug],
    enabled: Boolean(slug),
    queryFn: () => api.get(`/online-course/${slug}`)
  })
}

export const useCourseCompletedContents = (slug: string | undefined | null) => {
  return useQuery({
    queryKey: ['completed-contents', slug],
    enabled: Boolean(slug),
    queryFn: () => api.get(`/online-course/${slug}/completed`)
  })
}

export const useDeleteCourse = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    courseSuccessfullyDeletedT
  ] = [
    'courseSuccessfullyDeleted'
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (id: string) => api.delete(`/online-course/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['courses'])
      toast({
        title: courseSuccessfullyDeletedT
      })
    }
  })
}

// Enroll User to Course
export const useEnrollUserToCourse = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: (payload: {
      id: string
    }) => api.post(`/online-course/${payload.id}/enroll`),
    onSuccess: ({data}) => {
      queryClient.invalidateQueries(['enrolled-courses'])
      const {message, slug} = data
      toast({
        title: message
      })
      navigate(`/${slug}`)
    }
  })
}


// lectures
export const useGetLectureContents = (lectureId: string = "") => {
  return useQuery({
    enabled: Boolean(lectureId),
    queryKey: ['lecture-contents', lectureId],
    queryFn: () => api.get(`/lecture/${lectureId}/content`)
  })
}

export const useDeleteLecture = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    lectureSuccessfullyDeletedT
  ] = [
    'lectureSuccessfullyDeleted'
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (id: string) => api.delete(`/lecture/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['course-sections'])
      toast({ title:  `${lectureSuccessfullyDeletedT}` })
    }
  })
}


// langugaes
export const useGetContentLanguages = (contentId: string | null) => {
  return useQuery({
    enabled: Boolean(contentId),
    queryKey: ['content-languages', contentId],
    queryFn: () => api.get(`/lecture/content/${contentId}/language`)
  })
} 

export const useGetContentDataByLanguage = (langId: string | null) => {
  return useQuery({
    enabled: Boolean(langId),
    queryKey: ['content-data', langId],
    queryFn: () => api.get(`/lecture/content-data/language/${langId}`)
  })
} 

export const useCreateContentLanguage = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    languageSuccessfullyAdded,
    youCanCreateAsManyLanguageForYourContentToSupport,
  ] = [

  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (payload: {
      id: string,
      name: string
    }) => api.post(`/lecture/content/${payload.id}/language`, { name: payload.name }),
    onSuccess: () => {
      queryClient.invalidateQueries(['content-languages'])
      toast({
        title: `${languageSuccessfullyAdded}`,
        description: `${youCanCreateAsManyLanguageForYourContentToSupport}`,
      })
    }
  })
}

export const useCreateContentData = () => {
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    contentDataSuccessfullyUpdatedT
  ] = [
    'contentDataSuccessfullyUpdated'
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (payload: {
      id: string,
      data: ContentDataDTO
    }) => api.post(`/lecture/content/language/${payload.id}`, payload.data),
    onSuccess: () => {
      toast({
        title: `${contentDataSuccessfullyUpdatedT}`,
      })
    }
  })
}
