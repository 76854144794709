import { isEmpty } from 'lodash'
import { FC, useMemo } from 'react'

const loginIconMap: Record<string, string[]> = {
  'Aiforfuture': ['CUHK.png', 'HKJCCT.png'],
  'CUHKJC-Aiforfuture': ['CUHK.png', 'HKJCCT.png'],
}

const DefLoginIcons:FC = () => {
  const schoolEnv = import.meta.env.VITE_APP_SCHOOL_ENV

  const loginLogo = useMemo(() => {
    const data = loginIconMap[schoolEnv];

    return isEmpty(data) ? ['/kep-icon.png'] : data
  }, [schoolEnv])

  return (
    <>
      {loginLogo.map((img, key) => (
        <img 
          key={key}
          src={img} alt={img} />
      ))}
    </>
  )
}

export default DefLoginIcons