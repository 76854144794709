import { FC, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import { Input } from '@/components/ui/input';
import { useForm } from 'react-hook-form';
import { Button } from '../ui/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { get } from 'lodash';
import { useCreateLevel, useGetLevelData, useUpdateLevel } from '@/hooks/level';
import { Level, LevelDTO, levelSchema } from '@/types/level';
import { Label } from '../ui/label';
import TranslationWrapper from '../translation-wrapper';
import ReactCountryFlag from 'react-country-flag';
import { isDefaultLang } from '../translation-wrapper/isDefault';
import { makeLangDefault } from '../translation-wrapper/makeAsDefault';

type Props = {
  isOpen: boolean;
  closeModal: () => void
}

export type Ilocalization = { 
  name: string;
  code: string;
  default?: boolean | undefined;
}

export type Itranslation = {
  [key:string]: {name: string}
}

export const showFlag = (code) => {
  return <ReactCountryFlag style={{fontSize: '25px'}} countryCode={code} className='mx-1' />
}

const LevelFormModal:FC<Props> = ({ isOpen, closeModal }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const mode = searchParams.get('mode')
  const id = searchParams.get('id')

  const createLevel = useCreateLevel()
  const updateLevel = useUpdateLevel()
  const levelQuery = useGetLevelData(id)
  
  const form = useForm<LevelDTO>({
    resolver: zodResolver(levelSchema),
    defaultValues: {
      name: '',
      order: 0,
      translation: undefined,
    }
  })

  function handleCreateLevel(data: LevelDTO) {
    if (mode === 'edit' && id) {
      updateLevel.mutate({ id, data }, {
        onSuccess: () => {
          closeModal()
        }
      })

    } else {
      createLevel.mutate(data, {
        onSuccess: () => {
          closeModal()
        }
      })
    }
  }
  
  function getError(name: keyof LevelDTO): string {
    return form.formState.errors[name]?.message as string ?? ''
  }
  
  useEffect(() => {
    if (!isOpen) {
      navigate(window.location.pathname)
      form.reset({
        name: '',
        order: 0,
        translation: undefined,
      })
    }
    // eslint-disable-next-line
  }, [isOpen])

  useEffect(() => {
    if (levelQuery.isSuccess) {
      form.reset({
        name: level.name,
        order: level.order,
        translation: level.translation
      })
    }
    
    // eslint-disable-next-line
  }, [levelQuery.data])
  
  const level: Level = get(levelQuery, 'data.data', {})

  const LevelForm = (locale, localization, setLocalization, form) => {
    return <form 
      className="mt-4 flex flex-col gap-y-5"
      onSubmit={form.handleSubmit(handleCreateLevel)}>
      <div className='flex justify-start items-center'>
        <div className='w-[18px]'>
          <Input type='radio' checked={isDefaultLang(locale, localization)} className='mr-4'
            onChange={() => makeLangDefault(
              locale,
              localization,
              setLocalization,
              form,
              mode as string
            )}
          />
        </div>
        <Label className='ml-3'>Mark as default</Label>
      </div>
      
      <div>
          <Input 
            {...(isDefaultLang(locale, localization)
            ? { ...form.register(`name`)}
            : {...form.register(`translation.${locale}.name`)})}
            label="Level Name"
            id="level-name"
            disabled={levelQuery.isLoading}
            placeholder='Medium'
            type="text" />
        <p className="text-xs text-red-500 mt-1">{getError('name')}</p>
      </div>

      <div>
        <Input 
          {...form.register('order', { valueAsNumber: true })}
          label="Order"
          id="level-order"
          disabled={levelQuery.isLoading || !isDefaultLang(locale, localization)}
          placeholder='0'
          type="number" />
        <p className="text-xs text-red-500 mt-1">{getError('order')}</p>
      </div>

      <div className="flex justify-end mt-10">
        <Button 
          disabled={levelQuery.isLoading || createLevel.isLoading || updateLevel.isLoading}
          type="submit">
          {mode === 'edit' ? 'Save Changes' : 'Create'}
        </Button>
      </div>
    </form>
  }

  return (
    <Dialog open={isOpen} onOpenChange={closeModal}>
      <DialogContent className="shadow-2xl border-0 shadow-tertiary bg-ol-primary max-w-2xl">
        <DialogHeader>
          <DialogTitle className="text-white">
            {mode === 'edit' ? 'Update' : 'Create'}
          </DialogTitle>
          <DialogDescription className="text-white text-opacity-50">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nos
          </DialogDescription>
        </DialogHeader>

        {<TranslationWrapper
          isOpen={isOpen}
          form={form as never}
          formQuery={levelQuery}
          LevelForm={LevelForm}
        />}
      </DialogContent>
    </Dialog>
  )
}

export default LevelFormModal