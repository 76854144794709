import decodeJWT from "@/helper/jwt"
import {get} from "lodash"

const posthogSetIdentity = (userToken:string) => {
  const token = decodeJWT(userToken);
  window.posthog?.identify(get(token, 'email', ''), {
    email: get(token, 'email', ''),
    // name: token.info.name,
  })  
}

export { posthogSetIdentity };