import { Ilocalization } from "../form-modals/level";

export const isDefaultLang = (
  val: string, 
  localization: Ilocalization[], 
) => {
  for(const eachLocale of localization as Ilocalization[]){
    if((eachLocale.code === val) && eachLocale?.default){
      return true
    }
  }
  return false
};
