import { FC, useEffect } from 'react';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { DashboardIcon, ListBulletIcon } from '@radix-ui/react-icons';
import { useMediaQuery } from 'react-responsive';

type Props = {
  cardType: string
  setCardType: (val: string) => void
}

const CourseTypeToggle: FC<Props> = ({ cardType, setCardType }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 639px)' });
  
  useEffect(() => {
    if(isMobile){
      setCardType('compact')
    }
  }, [isMobile, setCardType, cardType])

  return (
    <ToggleGroup.Root
      className="ToggleGroup text-white"
      type="single"
      defaultValue="full"
      aria-label="Card type"
      onValueChange={(value) => {
        if(value === ''){
          setCardType('full')  
        }else{
          setCardType(value)
        }
      }}
      value={cardType}
    >
      <ToggleGroup.Item className="ToggleGroupItem bg-ol-primary cursor-pointer focus:outline-none" value="compact" aria-label="Compact Cards">
        <DashboardIcon />
      </ToggleGroup.Item>
      <ToggleGroup.Item className="ToggleGroupItem bg-ol-primary cursor-pointer focus:outline-none" value="full" aria-label="Full Cards">
        <ListBulletIcon />
      </ToggleGroup.Item>
    </ToggleGroup.Root>
  )
}

export default CourseTypeToggle