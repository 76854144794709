import { User } from '@/types/user'
import { create } from 'zustand'

type UserState = {
  currentUser: User | null
  isAuthenticated: boolean
  completedContents: string[],
  submittedAssignments: string[],
  setCurrentUser: (user: User) => void
  setCompletedContents: (completedContents: string[]) => void
  setSubmittedAssignments: (submittedAssignments: string[]) => void
}

export const useUserStore = create<UserState>()((set) => ({
  completedContents: [],
  submittedAssignments: [],
  currentUser: null,
  isAuthenticated: false,

  setCompletedContents: (completedContents: string[]) => set({ completedContents }),
  setSubmittedAssignments: (submittedAssignments: string[]) => set({ submittedAssignments }),
  clearUserSession: () => set({ 
    isAuthenticated: false,
    currentUser: null
  }),
  setCurrentUser: (user: User) => set({ 
    isAuthenticated: true,
    currentUser: user
  })
}))