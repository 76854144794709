import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useLogin } from '@/hooks/auth'
import { EyeIcon, EyeOffIcon, Globe, SunMoon } from 'lucide-react'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import DefLoginIcons from '@/components/ui/def-login-icons'
import { toggleDarkMode } from '@/main'

const loginDtoSchema = z.object({
  identifier: z.string().min(1, { message: 'Email / Username is required'}),
  password: z.string().min(1, { message: 'Password is required'})
})

export type LoginDTO = z.infer<typeof loginDtoSchema>

const LoginPage:FC = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [showLoginMobile, setShowLoginMobile] = useState(false)

  const login = useLogin()

  const form = useForm<LoginDTO>({
    resolver: zodResolver(loginDtoSchema),
    defaultValues: {
      identifier: '',
      password: ''
    },
  })

  function handleLogin(data: LoginDTO) {
    login.mutate(data)
  }

  function getError(name: keyof LoginDTO): string {
    return form.formState.errors[name]?.message ?? ''
  }

  const toggleShowPassword = () => {
    const currentShowPassword = showPassword
    setShowPassword(!currentShowPassword)
  }
  const { i18n, t } = useTranslation()
  const [
    loginToOnlineLearningT,
    enterYourEmailUsernameAndPasswordBelowToProceedT,
    emailOrUsernameT,
    passwordT,
    signInWithCredentialsT,
    orContinueWithT,
    signInWithGoogleT,
    signInWithMicrosoftT,
    byClickingContinueYouAgreeToOurT,
    termsOfServiceT,
    andT,
    privacyPolicyT,
  ] = [
    'loginToOnlineLearning',
    'enterYourEmailUsernameAndPasswordBelowToProceed',
    'emailOrUsername',
    'password',
    'signInWithCredentials',
    'orContinueWith',
    'signInWithGoogle',
    'signInWithMicrosoft',
    'byClickingContinueYouAgreeToOur',
    'termsOfService',
    'and',
    'privacyPolicy',
  ].map(key => get(t('loginPage'), key, ''))

  return (
    <div className="grid grid-cols-12 h-screen">
      <div 
        style={{
          backgroundImage: 'url("/blue_futuristic_technology_linktree_background.png")'
        }}
        className="col-span-12 lg:col-span-6 flex items-center justify-center">
        <div className="p-5 flex flex-col gap-y-12">
          <DefLoginIcons />
        </div>
      </div>

      <div className={` ${!showLoginMobile ? 'mt-[100vh] h-0 overflow-hidden' : 'h-[80%] mt-[20%]' } transform w-full transition-all duration-500 absolute lg:h-full lg:overflow-auto lg:m-auto lg:relative lg:block col-span-12 lg:col-span-6`}>
        <span
          className='lg:hidden block absolute left-5 top-5 cursor-pointer'
          onClick={() => setShowLoginMobile(false)}>
          Back
        </span>
        <Link 
          className='absolute right-5 top-5'
          to="/">
          Sign Up
        </Link>
        <Link 
          className='absolute right-44 top-5'
          to="/">
          <DropdownMenu>
            <DropdownMenuTrigger className="focus:outline-0 border-0">
              <Globe size={25} className='block'/>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="mt-3 shadow-lg border-0 px-3 z-50">
              {[
                {
                  buttonName: 'English',
                  buttonCode: 'en',
                  buttonFn: (code: string) => {
                    i18n.changeLanguage(code)
                    localStorage.setItem('i18nextLng', code);
                  }
                },
                {
                  buttonName: '繁體中文 (Traditional)',
                  buttonCode: 'zh',
                  buttonFn: (code: string) => {
                    i18n.changeLanguage(code)
                    localStorage.setItem('i18nextLng', code);
                  }
                },
                {
                  buttonName: '简体中文 (Simplified)',
                  buttonCode: 'ch',
                  buttonFn: (code: string) => {
                    i18n.changeLanguage(code)
                    localStorage.setItem('i18nextLng', code);
                  }
                },
              ].map((eachBtn, index) => {
                return (
                  <DropdownMenuItem 
                    key={index}>
                      <button
                      className={`
                        py-3 border-0 focus:outline-0 hover:opacity-60 flex items-center gap-x-3 justify-start w-full
                        ${i18n.language === eachBtn.buttonCode && 'text-ol-success'}
                        `}
                      onClick={() => eachBtn.buttonFn(eachBtn.buttonCode)}
                      type="button"
                    >
                      <span>{eachBtn.buttonName}</span>
                    </button>
                  </DropdownMenuItem>
                )
              })}
            </DropdownMenuContent>
          </DropdownMenu>
        </Link>

        {import.meta.env.VITE_APP_SCHOOL_ENV === "Aiforfuture" ? <></> : 
          <SunMoon className='absolute right-28 top-5' onClick={toggleDarkMode} />
        }

        <form 
          onSubmit={form.handleSubmit(handleLogin)}
          className="flex gap-y-3 flex-col items-center justify-center h-full w-full max-w-80 mx-auto">
          <p className="text-2xl font-semibold">{loginToOnlineLearningT}</p>
          <p className="text-sm text-gray-400 text-center mb-5">
            {enterYourEmailUsernameAndPasswordBelowToProceedT}
          </p>
          
          <div className="w-full">
            <Input 
              className='text-white'
              placeholder={emailOrUsernameT}
              {...form.register('identifier')}
            />
            <p className="text-xs text-red-500 mt-1">{getError('identifier')}</p>
          </div>

          <div className="w-full">
            <div className='flex items-center bg-ol-secondary rounded-lg text-white'>
              <Input 
                className='text-white'
                type={showPassword ? 'text' : 'password'}
                placeholder={passwordT}
                {...form.register('password')}
              />
              {!showPassword && <EyeIcon className='mr-2 text-white cursor-pointer' 
                onClick={() => toggleShowPassword()}
              />}
              {showPassword && <EyeOffIcon className='mr-2 text-white cursor-pointer' 
                onClick={() => toggleShowPassword()}
              />}
            </div>
            <p className="text-xs text-red-500 mt-1">{getError('password')}</p>
          </div>

          <Button
            disabled={login.isLoading}
            className='w-full px-0'
            type='submit'>
            {signInWithCredentialsT}
          </Button>

          <div className="relative w-full my-5">
            <hr className="w-full bg-white opacity-10" />
            <p className="left-1/2 transform -translate-x-1/2 px-2 absolute uppercase  -top-2 text-xs text-gray-400">
              {orContinueWithT}
            </p>
          </div>

          <div className="mb-5 w-full flex flex-col gap-y-3">
            <Link to={`${import.meta.env.VITE_APP_API_URL}/auth/google?type=online_learning`}>
              <Button
                variant="outline"
                className='w-full px-0 flex items-center justify-center gap-x-2 bg-ol-primary text-white'
                type='button'>
                <img 
                  className='h-5 w-5'
                  src="/google-icon.svg" alt="google icon" loading="lazy"/>
                {signInWithGoogleT}
              </Button>
            </Link>
            <Link to={`${import.meta.env.VITE_APP_API_URL}/auth/microsoft?type=online_learning`}>
              <Button
                variant="outline"
                className='w-full px-0 flex items-center justify-center gap-x-2 bg-ol-primary text-white'
                type='button'>
                <img 
                  className='h-5 w-5'
                  src="/microsoft-icon.svg" alt="google icon" loading="lazy"/>
                {signInWithMicrosoftT}
              </Button>
            </Link>
          </div>

          <p className="text-sm text-gray-400">
            {byClickingContinueYouAgreeToOurT} <br/>
            <span className="underline">{termsOfServiceT}</span> {andT} <span className="underline">{privacyPolicyT}</span>.
          </p>
        </form>
      </div>
    </div>
  )
}

export default LoginPage