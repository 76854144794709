import { useToast } from "@/components/ui/use-toast"
import { api } from "@/lib/axios"
import { ContentDataDTO } from "@/types/course"
import { ContentDto } from "@/types/lecture"
import { get } from "lodash"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"

export const useGetContentData = (id: string | null, withAttempt?: boolean) => {
  return useQuery({
    enabled: Boolean(id),
    queryKey: ['content', id, withAttempt],
    queryFn: () => api.get(`/lecture/content/${id}`, {
      params: { withAttempt }
    })
  })
}

export const usePatchContent = () => {
  const queryClient = useQueryClient()
  type Payload = {
    id: string,
    data: Record<string, string | boolean | number>
  }

  return useMutation({
    mutationFn: (payload: Payload) => api.patch(`/lecture/content/${payload.id}`, payload.data),
    onSuccess: () => {
      queryClient.invalidateQueries(['feedback-slug'])
    }
  })
}


export const useCreateContent = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    contentSuccessfullyCreatedT
  ] = [
    'contentSuccessfullyCreated',
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (payload: {
      data: ContentDto,
      lectureId: string
    }) => api.post(`/lecture/${payload.lectureId}/content`, payload.data),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(['course-sections'])
      queryClient
        .invalidateQueries(['lecture-contents'])
        .then(() => {
          toast({
            title: `${contentSuccessfullyCreatedT}`
          })
          navigate(`${window.location.pathname}?id=${data.content._id}`)
        })

    }
  })
}

export const useUpdateContent = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    contentSuccessfullyUpdatedT
  ] = [
    'contentSuccessfullyUpdated',
  ].map(key => get(t('toast'), key, ''))

  type Payload = {
    id: string
    data: ContentDataDTO
  }

  return useMutation({
    mutationFn: (payload: Payload) => api.put(`/lecture/content/${payload.id}`, payload.data),
    onSuccess: () =>{ 
      queryClient.invalidateQueries(['course-sections'])
      queryClient.invalidateQueries(['lecture-contents'])
      toast({
        title: `${contentSuccessfullyUpdatedT}`
      })
    }
  })
}

export const useMarkContentAsComplete = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    ContentMarkedAsCompleteT
  ] = [
    'ContentMarkedAsComplete',
  ].map(key => get(t('toast'), key, ''))

  type Payload = {
    id: string
  }

  return useMutation({
    mutationFn: (payload: Payload) => api.post(`/lecture/content/${payload.id}/complete`),
    onSuccess: () =>{ 
      queryClient.invalidateQueries(['course-progress'])
      queryClient.invalidateQueries(['completed-contents'])
      toast({
        title: `${ContentMarkedAsCompleteT}`
      })
    }
  })
}

export const useDeleteContent = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation() 
  const [
    contentSuccessfullyDeletedT
  ] = [
    'contentSuccessfullyDeleted',
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (id: string) => api.delete(`/lecture/content/${id}`),
    onSuccess: () =>{ 
      queryClient.invalidateQueries(['content'])
      queryClient.invalidateQueries(['course-sections'])
      queryClient
        .invalidateQueries(['lecture-contents',])
        .then(() => {
          toast({
            title: `${contentSuccessfullyDeletedT}`
          })
          navigate(window.location.pathname)
        })
    }
  })
}