import React, { FC, useEffect, useMemo } from 'react';
import { get, isNil, isEmpty, startCase, upperCase } from 'lodash';
import { Content } from '@/types/lecture';
import { Button } from '../button';
import { api } from '@/lib/axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useToast } from '../use-toast';
import { useMarkContentAsComplete } from '@/hooks/content';
import { useUserStore } from '@/store/user';
import { Question } from '@/types/quiz';
import { useFieldArray, useForm } from 'react-hook-form';
import { cn } from '@/lib/utils';
import { useReviewStatusQuiz, useStartQuiz, useSubmitQuiz } from '@/hooks/quiz';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight, ChevronFirst, ChevronLast, Notebook } from 'lucide-react';
  // Expand, Minimize, ZoomOutIcon, ZoomInIcon} from 'lucide-react';
import { useGetFeedbacks, useReactToContent } from '@/hooks/feedback';
import { FeedbackRes } from '@/types/feedback';
import ReactQuill from 'react-quill';
import Confetti from 'react-confetti';
import AssignmentContent from '@/components/assignment-content';
import Loading from '@/components/loading';
import Feedback from './Feedback';
import ReactButtons from '@/components/react-buttons';
import VideoPlayer from '@/lib/video-player';
import { pdfjs } from 'react-pdf';
import { Input } from '../input';
import { Textarea } from '../textarea';
import { ReactSVG } from 'react-svg';
import { TabsComponent } from '../tabs';
import { useJupyterRequestAccess } from '@/hooks/lti';
import SectionMenu from '../section-menu';
import { downloadableContent, MaterialsTab } from './materialsTab';
import { useGetCourseData } from '@/hooks/course';
import { Badge } from '@/components/ui/badge';
import { Viewer, Icon, Position, Tooltip, SpecialZoomLevel, PageChangeEvent } from '@react-pdf-viewer/core';
// import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import { pageNavigationPlugin, RenderGoToPageProps } from '@react-pdf-viewer/page-navigation';
import disableScrollPlugin from '@/helper/react-pdf-viewer/disable-scrolling-plugin';
import readingIndicatorPlugin from '@/helper/react-pdf-viewer/reading-indicator-plugin';
// import { zoomPlugin } from '@react-pdf-viewer/zoom';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
// import '@react-pdf-viewer/full-screen/lib/styles/index.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();
export interface ILectureContentProps {
  content: Content;
  slug?: string;
  flStatus: { isFirstContent: boolean; isLastContent: boolean };
}

const LectureContent: FC<ILectureContentProps> = ({ content, flStatus }) => {
  const navigate = useNavigate();
  const { id, slug } = useParams();
  const { toast } = useToast();
  const { completedContents } = useUserStore();
  const feedbacks: FeedbackRes[] | [] = get(useGetFeedbacks(id), 'data.data.feedbacks', []);
  const requestJupyterAccess = useJupyterRequestAccess()
  const courseData = useGetCourseData(slug)
  const courseDescription = get(courseData, 'data.data.description')

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { GoToFirstPage, GoToLastPage, GoToNextPage, GoToPreviousPage, CurrentPageInput, NumberOfPages } = pageNavigationPluginInstance;
  
  const disableScrollPluginInstance = disableScrollPlugin();
  
  // const FullScreenPluginProps = {
  //   // enableShortcuts: true,
  //   // getFullScreenTarget:() => {},
  //   // onEnterFullScreen: () => {},
  //   // onExitFullScreen: () => {},
  //   renderExitFullScreenButton: (props) => (
  //     <div
  //       style={{
  //         bottom: '1rem',
  //         position: 'fixed',
  //         right: '1rem',
  //         // Otherwise, the button will be hidden
  //         zIndex: 1,
  //       }}
  //     >
  //       <Button onClick={props.onClick}>
  //         <Icon size={16}><Minimize/></Icon>
  //       </Button>
  //     </div>
  //   ),
  // }

  //const fullScreenPluginInstance = fullScreenPlugin(FullScreenPluginProps);
  // const { EnterFullScreen } = fullScreenPluginInstance;

  //const zoomPluginInstance = zoomPlugin();
  // const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;

  const readingIndicatorPluginInstance = readingIndicatorPlugin();
  const { ReadingIndicator } = readingIndicatorPluginInstance;

  const handlePageChange = (e: PageChangeEvent) => {
    if((e.currentPage+1) === e.doc.numPages){
      if (!isCompleted) {
        markAsComplete.mutate({ id: id as string })
      }
    }
  }

  const { t } = useTranslation()
  const [completedT] = ['completed'].map(key => get(t('week'), key, ''))
  const [
    previousT,
    nextT,
    markAsCompleteT,
    commentsT,
    descriptionT,
    courseDetailsT,
    courseCatalogT,
    courseMaterialsT,
    assignmentsT,
    quizT,
    noFeedbackT,
    downloadMaterialsT,
    openInJupyterhubT
  ] = [
    'previousPage',
    'nextPage',
    'markAsComplete',
    'comments',
    'description',
    'courseDetails',
    'courseCatalog',
    'courseMaterials',
    'assignments',
    'quiz',
    'noFeedback',
    'downloadMaterials',
    'openInJupyterhub',
  ].map(key => get(t('LectureContent'), key, ''))
  const [
    readingT,
    videoT,
    assignmentT,
    pdfT,
  ] = [
    `${content.type}`,
  ].map(key => get(t('manageCourses'), key, ''))
  const [
    subtitleT,
  ] = [
    'subtitle',
  ].map(key => get(t('manageCourses'), key, ''))
  const contentType = readingT || videoT || quizT || assignmentT || pdfT
  const [
    downloadT,
  ] = [
    'download',
  ].map(key => get(t('MaterialTab'), key, ''))

  const pdfUrl = useMemo(() => {
    return get(content, 'pdf.url', '')
  }, [content])

  const reactToContent = useReactToContent()
  const markAsComplete = useMarkContentAsComplete();
  const isCompleted = useMemo(() => {
    if (id) return completedContents.includes(id);

    return false;
  }, [id, completedContents]);

  const handleDirectionClick = async (direction: 'previous' | 'next') => {
    const { data } = await api.get(`/lecture/content/${id}/${direction}`);

    const contentId: string = get(data, 'data._id');
    if (contentId) {
      navigate(`/${slug}/content/${contentId}`);
    } else {
      toast({
        title: 'There are no more items after current content',
      });
    }
  };

  function handleJupyterhubConnect() {
    const payload: Record<string, string | number> = {
      courseId: get(courseData, 'data.data._id', '')
    }

    requestJupyterAccess.mutate(payload, {
      onSuccess: (res) => {
        const { url, params } = res.data

        const form: HTMLFormElement | null = document.querySelector("#ltiForm");
        if (form) {
          form.innerHTML = "";
          form.action = url;
          form.method = "POST";
          form.target = '_blank';
          Object.keys(params).forEach(name => {
            const node = document.createElement("input");
            node.name = name;
            node.type = 'hidden';
            node.value = params[name] as string;
            form.appendChild(node);
          })
          form.submit();
        }
      }
    })
  }

  if (isNil(content)) {
    return <Loading />;
  }

  const schoolEnv = import.meta.env.VITE_APP_SCHOOL_ENV

  return (
    <section className="w-full relative pb-10">
      <form className="hidden" id="ltiForm" />
      <div>
        {content && content.type === "notebook" && (
          <div className="h-[320px] flex items-center justify-center">
            <Button
              disabled={requestJupyterAccess.isLoading}
              onClick={handleJupyterhubConnect}
              variant="outline"
              className="flex items-center justify-start gap-x-2 px-5 cursor-pointer bg-accent">
              {requestJupyterAccess.isLoading ? (
                <p>Requesting for access...</p>
              ) : (
                <>
                  <Notebook />
                    {openInJupyterhubT}
                </>
              )}
            </Button>
          </div>
        )}

        {content && content.type === 'video' && (
          <>
            <VideoPlayer
              url={
                get(content, 'video.type') === 'video/mp4' || get(content, 'video.type') === 'video/quicktime'
                  ? get(content, 'video.url', '')
                  : ''
              }
              subtitle={get(content, 'subtitle.vttText', '')}
              // light="https://narwhal-api2.krystal.education/test/13.png"
              onFinish={() => markAsComplete.mutate({ id: id as string })}
            />
          </>
        )}

        {content && content.type === 'quiz' && (
          <Questionnare content={content} />
        )}

        {content && content.type === 'pdf' &&  (
          <div>
            <div>
              <div className="border border-solid border-black/30 aspect-video">
                <ReadingIndicator/>
                {/* VIEWER PLUGINS fullScreenPluginInstance, zoomPluginInstance */}
                <Viewer plugins={[pageNavigationPluginInstance, disableScrollPluginInstance, readingIndicatorPluginInstance]} fileUrl={!isEmpty(pdfUrl) ? pdfUrl : "https://krystal.education/file.pdf"} onPageChange={handlePageChange}
                defaultScale={SpecialZoomLevel.PageFit}
                />
              </div>
              <div className="mt-2 flex justify-center gap-3">
                <GoToFirstPage>
                  {
                    (props: RenderGoToPageProps) => (
                      <Tooltip
                        position={Position.BottomCenter}
                        target={
                          <Button onClick={props.onClick} disabled={props.isDisabled}>
                            <Icon size={16}>
                              <ChevronFirst />
                            </Icon>
                          </Button>
                        }
                        content={() => 'First page'}
                        offset={{ left: 0, top: 8 }}
                      />
                    )
                  }
                </GoToFirstPage>
                <GoToPreviousPage>
                  {(props: RenderGoToPageProps) => (
                    <Tooltip
                      position={Position.BottomCenter}
                      target={
                        <Button onClick={props.onClick} disabled={props.isDisabled} >
                          <Icon size={16}>
                            <ChevronLeft />
                          </Icon>
                        </Button>
                      }
                      content={() => 'Previous page'}
                      offset={{ left: 0, top: 8 }}
                    />
                  )}
                </GoToPreviousPage>
                
                <span className="inline-block my-auto">
                  <div className="w-[38px] p-0 m-0 boarder-0 inline-block"><CurrentPageInput/></div>
                  <span className="m-4">/</span>
                  <span><NumberOfPages /></span>
                </span>
                <GoToNextPage>
                  {(props: RenderGoToPageProps) => (
                    <Tooltip
                      position={Position.BottomCenter}
                      target={
                        <Button onClick={props.onClick} disabled={props.isDisabled}>
                          <Icon size={16}>
                            <ChevronRight />
                          </Icon>
                        </Button>
                      }
                      content={() => 'Next page'}
                      offset={{ left: 0, top: 8 }}
                    />
                  )}
                </GoToNextPage>
                <GoToLastPage>
                  {
                    (props: RenderGoToPageProps) => (
                      <Tooltip
                        position={Position.BottomCenter}
                        target={
                          <Button onClick={props.onClick} disabled={props.isDisabled}>
                            <Icon size={16}>
                              <ChevronLast />
                            </Icon>
                          </Button>
                        }
                        content={() => 'Last page'}
                        offset={{ left: 0, top: 8 }}
                      />
                    )
                  }
                </GoToLastPage>
              </div>
              { /* Todo:
                - Button to return to default fit 
                - In Full Screen added navgiation buttons
                - In Full screen Mode add default fit button
                - In Full screen mode add zoom in zoom out
                */ }

              {/* <div>
                <ZoomIn>
                  {
                    (props: RenderZoomInProps) => (
                      <Button
                        style={{

                        }}
                        onClick={props.onClick}
                      >
                        <Icon size={16}>
                          <ZoomInIcon />
                        </Icon>
                      </Button>
                    )
                  }
                </ZoomIn>
                <ZoomOut>
                  {
                    (props: RenderZoomOutProps) => (
                      <Button
                        style={{
                          
                        }}  
                        onClick={props.onClick}
                      >
                        <Icon size={16}>
                        <ZoomOutIcon/>
                        </Icon>
                      </Button>
                    )
                  }
                </ZoomOut>
                <EnterFullScreen>
                  {
                    (props: RenderEnterFullScreenProps) => (
                      <Button onClick={props.onClick}>
                        <Icon size={16}>
                          <Expand /> 
                        </Icon>
                      </Button>
                    )
                  }
                </EnterFullScreen>
              </div> */}
            </div>

          </div>
        )}

        {content && content.type === 'assignment' && (
          <AssignmentContent content={content} />
        )}

        {content && content.type === 'reading' && (
          <div>
            <p className="text-[24px] font-bold">
              Reading - {content.title}
            </p>
            <p className="mt-5">
              <style>{`.ql-clipboard { display: none !important; }`}</style>
              <ReactQuill
                theme="bubble"
                className="bg-transparent"
                defaultValue={content.readingContent}
                readOnly={true}
                modules={{ toolbar: false }}
              />
            </p>
          </div>
        )}
      </div>

      <div className="md:flex justify-between border-t pt-3 border-tertiary mt-10 gap-x-5 items-center">
        <div className="justify-between items-center border-r md:pr-5 border-primary">
          <div className='flex justify-between md:justify-start gap-x-3 items-center'>
            <ReactButtons
              likeCount={content.likes || 0}
              loading={reactToContent.isLoading}
              liked={content.user_react && content.user_react.includes('like')}
              // smiled={content.user_react && content.user_react.includes('smile')}
              hearted={content.user_react && content.user_react.includes('heart')}
              // poppered={content.user_react && content.user_react.includes('popper')}
              // disliked={content.user_react && content.user_react === 'dislike'}
              // onDislikeClick={() => reactToContent.mutate({ id: id as string, type: 'dislike' })}
              onLikeClick={() => reactToContent.mutate({ id: id as string, type: 'like' })}
              // onSmileClick={() => reactToContent.mutate({ id: id as string, type: 'smile' })}
              onHeartClick={() => reactToContent.mutate({ id: id as string, type: 'heart' })}
              // onPopperClick={() => reactToContent.mutate({ id: id as string, type: 'popper' })}
            />

            <>
              {isCompleted ? (
                <Badge className="inline bg-active-bg text-center h-[38px] flex whitespace-nowrap">{completedT}</Badge>
              ) : (
                <Button
                  variant="secondary"
                  disabled={markAsComplete.isLoading}
                  className="inline px-5 disabled:opacity-60 disabled:cursor-progress"
                  onClick={() => markAsComplete.mutate({ id: id as string })}
                >
                  {markAsCompleteT}
                </Button>
              )}
            </>
          </div>
        </div>

        <div className="flex mt-3 md:mt-0 w-full justify-between">
          {!flStatus.isFirstContent && (
            <Button
              variant="outline"
              className="flex items-center justify-start px-3"
              onClick={() => handleDirectionClick('previous')}>
              <ChevronLeft />
              {startCase(previousT)}
            </Button>
          )}



          {!flStatus.isLastContent && (
            <Button
              variant="outline"
              className="flex items-center justify-end px-3"
              onClick={() => handleDirectionClick('next')}
            >
              {startCase(nextT)}
              <ChevronRight className="h-5 w-5" />
            </Button>
          )}
        </div>
      </div>
      <div className="mt-5 bg-primary p-3 md:p-4 rounded-xl border-2 bg-ol-tertinary">
        {
          TabsComponent(
            [
              descriptionT, 
              courseDetailsT, 
              ...(schoolEnv !== 'Aiforfuture' ? [courseCatalogT] : []),
              commentsT, 
              ...(schoolEnv !== 'Aiforfuture' ? [courseMaterialsT] : []),
              ...(schoolEnv !== 'Aiforfuture' ? [assignmentsT] : []),
              ...(schoolEnv !== 'Aiforfuture' ? [quizT] : [])
            ],
            [
              <div className='text-sm md:text-base'>
                <p className="font-semibold text-sm text-text">{descriptionT}:</p>
                <p className="hidden md:block text-text">
                  {content.description}
                </p>
                {
                  (content.type === 'video' ||
                    content.type === 'assignment' ||
                    content.type === 'subtitle' ||
                    content.type === 'pdf' ||
                    content.type === 'notebook') && content[content.type] && content['allow_download'] && <>
                    <p className="font-semibold text-sm mt-3 pt-3 border-t border-ol-tertiary text-orange-600">{downloadMaterialsT}:</p>
                    {downloadableContent(content, content.type, contentType, downloadT)}
                    {content.type === 'video' && content['subtitle'] && downloadableContent(content, 'subtitle', subtitleT, downloadT)}
                    {content.attachments?.map((eachAttachment) => downloadableContent({...eachAttachment, title: content.title}, '', contentType, downloadT))}   
                  </>
                }
              </div>,
              <div className='text-sm md:text-base'>
                <p className="font-semibold text-sm text-text">{descriptionT}:</p>
                <p className="hidden md:block text-text">
                  {courseDescription}
                </p>
              </div>,
              ...(schoolEnv !== 'Aiforfuture' ? [<SectionMenu />] : []),
              <div>
                {id && get(content, 'comments_disabled', false) == false 
                  ? <Feedback feedbacks={feedbacks} week={false} />
                  : <p className="text-orange-400 font-semibold italic pt-5">{noFeedbackT}</p>
                }
              </div>,
              ...(schoolEnv !== 'Aiforfuture' ? [<MaterialsTab section='attachments' />] : []),
              ...(schoolEnv !== 'Aiforfuture' ? [<MaterialsTab section='assignment' />] : []),
              ...(schoolEnv !== 'Aiforfuture' ? [<MaterialsTab section='quiz' />] : [])
            ]
          )
        }
      </div>
    </section>
  );
};
interface IQuizContentProps {
  content: Content;
}

const Questionnare: React.FC<IQuizContentProps> = ({ content }) => {
  const { title, quiz } = content;
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { t } = useTranslation()
  const [
    startQuizT,
    scoreT,
    retakeQuizT,
    dueT,
    questionsT,
    quizT,
    submitT,
    thankyouMsgT,
    confirmSubmissionT,
    theQuizWillBeAvailableLaterT
  ] = [
    'startQuiz',
    'score',
    'retakeQuiz',
    'due',
    'questions',
    'quiz',
    'submit',
    'thankyouMsg',
    'confirmSubmission',
    'theQuizWillBeAvailableLaterT'
  ].map(key => get(t('LectureContent'), key, ''))

  const [confSize, setConfSize] = React.useState<number>(0);
  const [quizScore, setQuizScore] = React.useState<number | null>(null);
  const [quizReview, setQuizReview] = React.useState<boolean | null>(null);
  const [quizStarted, setQuizStarted] = React.useState<boolean>(false);

  const isReview = get(useReviewStatusQuiz(content?.quiz?._id), 'data.data', null)
  useEffect(() => {
    setQuizReview(isReview)
  }, [isReview])

  const startQuiz = useStartQuiz();
  const submitQuiz = useSubmitQuiz();

  const form = useForm<{ questions: Question[] }>({
    defaultValues: {
      questions: content?.quiz?.questions,
    },
  });

  const fieldArray = useFieldArray({
    control: form.control,
    name: 'questions',
  });

  const handleStartQuiz = () => {
    content?.quiz?._id && startQuiz.mutate(content?.quiz?._id, {
      onSuccess: (res) => {
        const token = get(res, 'data.attempt_token');
        const params = new URLSearchParams();

        params.append('attempt_token', token);
        navigate({
          pathname: window.location.pathname,
          search: params.toString(),
        });
        setQuizStarted(true);
      },
    });
  };

  const onSubmitQuiz = (data: { questions: Question[] }) => {
    const confirm = window.confirm(
      `${confirmSubmissionT}`
    );

    if (confirm) {
      submitQuiz.mutate(
        {
          id: content?.quiz?._id as string,
          token: params.get('attempt_token') as string,
          data: data.questions,
        },
        {
          onSuccess: (res) => {
            const reviewStatus = get(res, 'data.review')
            if(reviewStatus){
              setQuizReview(true)
              return
            }
            const score = get(res, 'data.score', 0);
            setQuizScore(score);
            setConfSize(200);
            setTimeout(() => setConfSize(0), 2000);
          },
        }
      );
    }
  };

  if (quizScore !== null) {
    return (
      <div className="flex justify-center items-center h-[520px]">
        <Confetti numberOfPieces={confSize} />
        <div className="flex flex-col items-center justify-center">
          <span className="text-3xl">
            {scoreT}: {quizScore}/{quiz?.questions?.reduce((sum, question) => sum + question.ques_score, 0)}
          </span>

          <button
            className="bg-info-500 mt-4 hover:bg-info-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              setQuizScore(null);
              form.reset({ questions: content?.quiz?.questions });
              handleStartQuiz();
            }}
          >
            {retakeQuizT}
          </button>
        </div>
      </div>
    );
  }

  if (quizReview) {
    return (
      <div className="flex justify-center items-center h-[520px]">
        <Confetti numberOfPieces={confSize} />
        <div className="flex flex-col items-center justify-center">
          <span className="text-3xl">
            {thankyouMsgT}
          </span>
        </div>
      </div>
    );
  }

  if (!quizStarted) {
    if (quiz) {
      return (
        <div className="flex flex-col justify-center items-center h-[65vh]">
          <p>{quiz?.name}</p>
          { quiz?.due_date && <p>{dueT}: {format(quiz?.due_date, 'PPppp')}</p> }
          <p>{questionsT}: {quiz?.questions?.length} </p>
          <Button
            disabled={startQuiz.isLoading}
            className="inline mt-5"
            onClick={handleStartQuiz}
          >
            {startQuiz.isLoading ? <Loading /> : `${startQuizT}`}
          </Button>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col justify-center items-center h-[65vh]">
          <p>{theQuizWillBeAvailableLaterT}.</p>
        </div>
      );
    }
  }

  return (
    <div>
      <p className="text-gray-50 text-[24px] font-bold">{quizT} - {title}</p>
      <p className="text-xl font-semibold mb-5">{quiz?.name}</p>

      <form onSubmit={form.handleSubmit(onSubmitQuiz)}>
        <div className="flex flex-col gap-y-4">
          {fieldArray.fields.map((question, index: number) => {
            return (
              <div
                key={question._id}
                className="rounded-lg bg-ol-tertiary shadow-lg p-3"
              >
                <p className="text-lg font-medium whitespace-pre-wrap">
                  {index + 1}
                  {') '}
                  {question.question} {`(${question.ques_score} marks)`}
                </p>
                {
                  question.imagesUrl && question.imagesUrl.map((eachImg) => {
                    if(eachImg.includes('.svg')){
                      return <ReactSVG src={eachImg} />
                    }
                    return <img src={eachImg} alt='image' loading="lazy" />
                  })
                }

                {
                  question.ques_type === 'fill_in'
                  ? <Input className='mt-3' onChange={(e) =>
                      form.setValue(
                        `questions.${index}.user_answer`,
                        e.target.value
                      )
                    } />
                  : question.ques_type === 'short_answer'
                  ? <Textarea className='mt-3' onChange={(e) =>
                      form.setValue(
                        `questions.${index}.user_answer`,
                        e.target.value
                      )
                    } />
                  : <div className="mt-3 grid grid-cols-12 gap-2">
                  {question.options.map((option) => {
                    const userAnswer = form.watch(
                      `questions.${index}.user_answer`
                    );

                    return (
                      <div className="col-span-6" key={option._id}>
                        <Button
                          variant="default"
                          className={cn(
                            'px-3 py-2 w-full flex justify-start border-2 font-medium',
                            `
                            ${userAnswer === option.key
                              ? 'bg-ol-info font-bold'
                              : 'bg-ol-secondary'
                            }
                          `
                          )}
                          onClick={() =>
                            form.setValue(
                              `questions.${index}.user_answer`,
                              option.key
                            )
                          }
                          type="button"
                        >
                          <p>
                            {upperCase(option.key)}. {option.text || 'N/A'}
                          </p>
                        </Button>
                      </div>
                    );
                  })}
                </div>
                }
              </div>
            );
          })}
        </div>

        <div className="text-center mt-10">
          <button
            type="submit"
            className="bg-info-500 w-[200px] hover:bg-info-700 text-white font-bold py-2 px-4 rounded"
          >
            <span className="text-lg">{submitT}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default LectureContent;
