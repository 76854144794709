import { get } from "lodash"
import { useTranslation } from "react-i18next"

export default function Loading(){
  const { t } = useTranslation()
  const [
    loadingT,
  ] = [
    'loading',
  ].map(key => get(t('common'), key, ''))
  return <p className="text-base lg:text-xl">{loadingT}</p>
}