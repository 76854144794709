// import { ThumbsDown, ThumbsUp, Smile, Heart, PartyPopper } from 'lucide-react'
import {ThumbsUp, Heart } from 'lucide-react'
import { FC } from 'react'
import cx from 'classnames'

type Props = {
  liked: boolean
  // disliked: boolean
  // smiled: boolean
  hearted: boolean
  // poppered: boolean
  likeCount: number
  loading: boolean
  onLikeClick: () => void
  // onDislikeClick: () => void
  // onSmileClick: () => void
  onHeartClick: () => void
  // onPopperClick: () => void
}

// const ReactButtons:FC<Props> = ({ liked, loading, disliked, smiled, hearted, poppered, onLikeClick, onDislikeClick, onSmileClick, onHeartClick, onPopperClick, likeCount }) => {
const ReactButtons: FC<Props> = ({ liked, loading, hearted, onLikeClick, onHeartClick, likeCount }) => {

  return (
    <div className={cx("gap-x-2 flex justify-between bg-ol-tertiary items-center rounded-md shadow-lg py-2 px-3", {
      'opacity-50 cursor-progress': loading
    })}>
      <button 
        className="border-0 outline-0"
        disabled={loading}
        type="button">
        <ThumbsUp
          fill={liked ? 'white' : 'none'} 
          className='cursor-pointer h-5 w-5' 
          onClick={onLikeClick}
        />
      </button>
      <div className="border-r pr-2 border-gray-500">
        <p className='border-ol-tertiary font-semibold'>{likeCount}</p>
      </div>
      {/* <button 
        disabled={loading}
        className="border-0 outline-0"
        type="button">
        <ThumbsDown
          fill={disliked ? 'white' : 'none'} 
          className='cursor-pointer h-5 w-5'
          onClick={onDislikeClick}
        />
      </button> */}
      {/* <button className="border-0 outline-0" type="button">
        <Smile 
          color={smiled ? 'black' : 'white'}
          fill={smiled ? '#fbdc34' : 'none'}
          className='cursor-pointer h-5 w-5' 
          onClick={onSmileClick}
        />
      </button> */}
      <button className="border-0 outline-0" type="button">
        <Heart
          fill={hearted ? 'red' : 'none'}
          className='cursor-pointer h-5 w-5'
          onClick={onHeartClick}
        />
      </button>
      {/* <button className="border-0 outline-0" type="button">
        <PartyPopper 
          color={poppered ? 'orange' : 'white'}
          fill={poppered ? 'yellow' : 'none'}
          className='cursor-pointer h-5 w-5'
          onClick={onPopperClick}
        />
      </button> */}
    </div>
  )
}

export default ReactButtons