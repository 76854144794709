import { useToast } from "@/components/ui/use-toast"
import { api, apiLegacy } from "@/lib/axios"
import { LoginDTO } from "@/pages/login"
import { useUserStore } from "@/store/user"
import { get } from "lodash"
import { useMutation, useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import {posthogSetIdentity} from "@/helper/auth-helper"


export const useGetMembers = (params: Record<string, string | number>) => {
  return useQuery({
    enabled: Boolean(params.name),
    queryKey: ['auth-all', params],
    queryFn: () => apiLegacy.get('/auth/all/members', { params }) 
  })
}

export const useQuickLogin = () => {
  const navigate = useNavigate()

  return useMutation({
    mutationFn: () => api.post('/auth/quick-login'),
    onSuccess: ({ data }) => {
      localStorage.setItem('user-token', data.result.token)
      navigate('/')
      return
    }
  })
}

export const useLogin = () => {
  const { toast } = useToast()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: (data: LoginDTO) => apiLegacy.post('/auth/login?type=online_learning', {
      username: data.identifier,
      password: data.password
    }),
    onSuccess: (res) => {
      localStorage.setItem('user-token', res.data.data)
      posthogSetIdentity(res.data.data)
      navigate('/')
    },
    onError: (err) => {
      console.log(err)
      const msg = get(err, 'response.data.message', 'Cannot Login')
      toast({
        title: msg,
        description: 'Something went wrong.'
      })
    }
  })
}

export const useGetValidInstructors = () => {
  return useQuery({
    queryKey: ['instructors'],
    queryFn: () => apiLegacy.get('/auth/valid-instructors')
  })
}

// Depreciated Function
export const useGetCurrentUser = (enabled: boolean = true) => {
  const userStore = useUserStore()

  return useQuery({
    enabled,
    queryKey: ['me'],
    queryFn: () => api.get('/auth/me'),
    onSuccess: ({ data }) => {
      userStore.setCurrentUser({
        _id: get(data, '_id'),
        email: get(data, 'info.email'),
        name: get(data, 'info.name'),
        role: get(data, 'info.role'),
        username: get(data, 'info.username'),
        profilePic: get(data, 'info.profilePic'),
      })    
    }
  })
}

export const useGetAccessToken = (payload:{
  grant_type:string
  code:string
  redirect_uri:string
  client_id:string
  client_secret:string
  code_verifier:string
}) => {
  const {grant_type, code, redirect_uri, client_id, client_secret, code_verifier} = payload;
  return useQuery({
    queryKey: [],
    queryFn: () => api.get(`/auth/getaccesstoken?grant_type=${grant_type}&code=${code}&redirect_uri=${redirect_uri}&client_id=${client_id}&client_secret=${client_secret}&code_verifier=${code_verifier}`),
    onSuccess: ({data}) => {
      //store API KEY
      localStorage.setItem('user-token', data.result.access_token)
      posthogSetIdentity(data.result.access_token)
      localStorage.setItem('logout-callback', data.result.logout_callback)
      localStorage.setItem('logout-redirect', data.result.logout_redirect)
      if(data.result.lang){
        localStorage.setItem('i18nextLng', data.result.lang)
      }
    }
  })
}

export const useLogout = () => {
  return useMutation({
    mutationFn: (payload: Record<string, string>) => apiLegacy.post('/auth/logout', payload),
    onSuccess: () => {
      const callback_url = localStorage.getItem('logout-redirect') || '/'
      const keys = Object.keys(localStorage);
      keys.forEach((key) => {
        if (key !== 'kep-ol-theme') {
          localStorage.removeItem(key);
        }
      });
      window.location.replace(callback_url)
    },
    onError: () => {
      alert('Cannot logout. Try again later')
    }
  })
}