import { FC } from 'react'
import { Outlet, Link, useParams, useMatches, UIMatch, Params, useNavigate } from 'react-router-dom'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
// import { useGetSectionsByCourse } from '@/hooks/section'
// import { ILecture } from '@/types/lecture';
import { useCourseCompletedContents, useGetCourseData } from '@/hooks/course';
import { CircleIcon, CheckCircleIcon } from 'lucide-react';
import { get, isUndefined, isNull } from 'lodash';
import { leftContentDuration } from '@/helper/leftContentDuration';
import { Section } from '@/types/section';
import { useTranslation } from 'react-i18next';
import Loading from '@/components/loading';

interface MatchProps extends UIMatch{
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: {
    selected:string;
  };
}

const CourseLayout: FC = () => {

  const { t } = useTranslation()
  const { slug } = useParams()
  const courseInfo = useGetCourseData(slug)
  const navigate = useNavigate()

  if (courseInfo.isSuccess && isNull(courseInfo.data?.data)){
    navigate('/404')
  }
  
  const completedContents = get(useCourseCompletedContents(slug), 'data.data.completedContents.completed_contents') || []

  // Find the current Selected page and display on menu
  const getMatches = useMatches() as MatchProps[];

  const [courseMaterialsT, gradesT, infoT] = ['courseMaterials', 'grades', 'info'].map((key) => get(t('courseLayout'), key, ''));

  const matches: MatchProps[] = getMatches.filter((match: MatchProps) => !isUndefined(match.handle?.selected))
  const selected = matches.length < 1 ? null : (matches[0].handle?.selected === "section" ? matches[0].params.id : matches[0].handle?.selected);
  
  if(courseInfo.isLoading){
    return <Loading />
  }
  return (
    <div className="lg:flex lg:m-8">
      <div className='lg:w-2/12 mt-2'>
        <div>
          <img className="hidden lg:block w-full" src={courseInfo.data?.data?.course_image?.url || '/default-course-thumbnail.png'} alt={courseInfo.data?.data?.course_image?.name || 'course_cover'} loading="lazy" />
          <p className="text-center lg:text-start text-2xl md:text-3xl lg:text-lg font-semibold lg:font-black mt-5 lg:mt-4">{courseInfo.data?.data.title}</p>
          <p className="hidden lg:block text-sm">{courseInfo.data?.data.subtitle}</p>
        </div>
        <div className='mt-4 lg:mt-0 flex flex-col items-start justify-between lg:block'>
          <p className='font-bold mt-5 text-xl lg:hidden'>Menu</p>
          <div>
            <Accordion className="border-0" type="single" collapsible defaultValue="coursematerials">
              <AccordionItem value="coursematerials">
                <AccordionTrigger>
                  <span className="text-sm lg:text-base text-left">{courseMaterialsT}</span>
                </AccordionTrigger>
                <AccordionContent className="pb-0">
                  {courseInfo.isSuccess && courseInfo.data?.data.sections.map((eachSection: Section) => {
                    if(eachSection.lectures.length > 0 && eachSection.lectures.some(lecture=> lecture.contents.length > 0 === true)){
                      const {totalDurationReading, totalDurationVideo, totalDurationQuiz} = leftContentDuration(eachSection, completedContents)
                      return (
                        <div className={`truncate text-ellipsis mb-3 text-base ${selected === eachSection._id ? 'border-l-4 border-ol-primary pl-6' : 'pl-7'}`} key={eachSection._id}>
                          {totalDurationReading === '0m'
                            && totalDurationVideo === '0m'
                            && totalDurationQuiz === '0m'
                            ? <CheckCircleIcon className="w-4 h-4 inline align-baseline mx-2 text-green-500"/>
                            : <CircleIcon className="w-4 h-4 inline align-baseline mx-2"/> 
                          }
                          <Link className="my-auto text-sm lg:text-base" to={`/${slug}/section/${eachSection._id}`}>{eachSection.name}</Link>
                        </div>
                      )
                    }
                    return
                  })}
                </AccordionContent>
              </AccordionItem>
            </Accordion>          
          </div>
          <div className={`mb-3 text-sm lg:text-base ${selected === "grades" ? 'border-l-4 border-ol-primary pl-6' : 'pl-7'}`}><Link className="my-4" to={`/${slug}/grades`}>{gradesT}</Link></div>
          <div className={`mb-3 text-sm lg:text-base ${selected === "info" ? 'border-l-4 border-ol-primary pl-6' : 'pl-7'}`}><Link className="my-4" to={`/${slug}/info`}>{infoT}</Link></div>
        </div>
      </div>
      <div className='lg:w-10/12 lg:mx-7 my-5'><Outlet /></div>
    </div>
  )
}

export default CourseLayout