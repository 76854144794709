import { useEffect, useState, FC } from 'react';
import { Progress } from '@/components/ui/progress';
import { Collapsible, CollapsibleContent } from '@/components/ui/collapsible';
import { Check, ChevronDownIcon } from 'lucide-react';
import { useGetCourseData, useGetUserProgress } from '@/hooks/course';
import { useGetSectionsByCourse } from '@/hooks/section';
import { Section } from '@/types/section';
import { Lecture, OnlineCourse } from '@/types/course';
import { useNavigate, useParams } from 'react-router-dom';
import { Badge } from './badge';
import { get } from 'lodash';
import { useUserStore } from '@/store/user';
import { useTranslation } from 'react-i18next';
import Loading from '../loading';

interface ISectionMenu {
  title?: string;
  sectionTitle?: string;
  showProgress?: boolean
}

const SectionMenu: FC<ISectionMenu> = ({ title, sectionTitle, showProgress }) => {
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedLecture, setSelectedLecture] = useState('');
  const [selectedContent, setSelectedContent] = useState('');
  const courseInfo = useGetCourseData(title);
  const sectionInfo = useGetSectionsByCourse(title, '');

  const { t } = useTranslation()
  const [completedT, completionT] = ['completed', 'completion'].map(key => get(t('week'), key, ''))

  const navigate = useNavigate();
  const { slug, id } = useParams();

  const { completedContents } = useUserStore();
  const course: OnlineCourse = get(courseInfo, 'data.data', {});
  const section: Section[] = get(sectionInfo, 'data.data', []);

  const progressInfo = (useGetUserProgress(showProgress && courseInfo.data?.data._id))
  const courseProgress = progressInfo?.data?.data.data.course_progress;

  const UpadateSectionMenuState = () => {
    if (selectedSection !== '') {
      return;
    }
    section &&
      section.length > 0 &&
      section.map((eachSection: Section) => {
        if (eachSection.name === sectionTitle) {
          setSelectedSection(eachSection._id);
          eachSection.lectures.map((eachLectures: Lecture) => {
            eachLectures.contents.map((eachContent: { _id: string }) => {
              if (eachContent._id === id) {
                setSelectedLecture(eachLectures._id);
                setSelectedContent(eachContent._id);
              }
            });
          });
        }
      });
  };
  useEffect(() => {
    UpadateSectionMenuState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, selectedSection]);

  useEffect(() => {
    setSelectedSection('');
    UpadateSectionMenuState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, id]);

  if (courseInfo.isLoading || sectionInfo.isLoading) {
    return <Loading />;
  }

  return (
    <div className="borde rounded-lg bg-ol-tertinary">
      {showProgress && <>
        <p className="p-4 font-bold text-white">{course.title}</p>
        <hr className="border border-ol-tertinary" />
        <div className="m-4">
          <p className="font-bold capitalize text-white">{completionT}</p>
          <div className="flex items-center mt-2">
            <Progress value={courseProgress} />
            <p className="mx-4 text-white">{courseProgress}%</p>
          </div>
        </div>
        <hr className="border border-ol-tertinary" />
      </>}
      {section.map((eachSection: Section) => {
        return (
          <Collapsible
            open={true}
            onClick={() => {
              setSelectedSection(eachSection._id);
            }}
            className="cursor-pointer mr-2.5 "
            key={eachSection._id}
          >
            <CollapsibleContent className="">
              <div className={`flex items-center`}>
                <p className="mx-2 md:mx-3 p-2 font-bold text-sm md:text-base">
                  <span className='text-white'>{eachSection.name}</span>
                  {eachSection.lectures.every((lec) =>
                    lec.contents.every((content) =>
                      completedContents.includes(content._id)
                    )
                  ) && <Badge className="bg-ol-success ml-4">{completedT}</Badge>}
                </p>
                <ChevronDownIcon className="w-6 h-6 ml-auto text-white" />
              </div>
              {eachSection.lectures.map((eachLectures: Lecture) => {
                return (
                  <Collapsible
                    key={eachLectures._id}
                    open={selectedSection === eachSection._id}
                    onClick={() => {
                      setSelectedLecture(eachLectures._id);
                    }}
                    className="cursor-pointer"
                  >
                    <CollapsibleContent className="">
                      <div className={`flex items-center`}>
                        {eachLectures.contents.every((content) =>
                          completedContents.includes(content._id)
                        ) ? (
                          <Check className="text-ol-success w-8 mt-2 ml-3" />
                        ) : (
                          <div className="ml-1 md:ml-3 w-4 md:w-8 mt-2" />
                        )}
                        <p className="p-2 text-white">{eachLectures.title}</p>
                        <ChevronDownIcon className="w-4 h-4 ml-auto mr-1 text-white" />
                      </div>
                      {eachLectures.contents.map((eachContents) => {
                        const [
                          readingT,
                          videoT,
                          quizT,
                          assignmentT,
                          pdfT,
                        ] = [
                          `${eachContents.type}`
                        ].map(key => get(t('manageCourses'), key, ''))
                        const contentType = readingT || videoT || quizT || assignmentT || pdfT
                        return (
                          <Collapsible
                            open={selectedLecture === eachLectures._id}
                            onClick={() => {
                              setSelectedContent(eachContents._id);
                              navigate(`/${slug}/content/${eachContents._id}`);
                            }}
                            className="cursor-pointer "
                            key={eachContents._id}
                          >
                            <CollapsibleContent className="">
                              <div
                                className={`flex ${
                                  selectedContent === eachContents._id &&
                                  'bg-ol-warning'
                                }`}
                              >
                                <span
                                  className={`px-1 ${
                                    selectedContent === eachContents._id &&
                                    'bg-ol-info'
                                  }`}
                                />
                                <div className="flex items-center">
                                  {completedContents.includes(
                                    eachContents._id
                                  ) ? (
                                    <Check className="text-ol-success ml-2 md:ml-10 w-6 mt-2" />
                                  ) : (
                                    <div className="ml-2 md:ml-10 w-4 md:w-6 mt-2" />
                                  )}
                                </div>
                                <p className="mx-1 md:mx-3 p-2 break-words overflow-hidden text-white">
                                  <span className="font-bold capitalize text-white">
                                    {contentType}:
                                  </span>{' '}
                                  {eachContents.title}
                                </p>
                              </div>
                            </CollapsibleContent>
                          </Collapsible>
                        );
                      })}
                    </CollapsibleContent>
                  </Collapsible>
                );
              })}
            </CollapsibleContent>
          </Collapsible>
        );
      })}
    </div>
  );
};
export default SectionMenu;
