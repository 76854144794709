import { SectionFormData } from "@/components/course-section"
import { useToast } from "@/components/ui/use-toast"
import { api } from "@/lib/axios"
import { get, omit } from "lodash"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"

export const useGetSectionsByCourse = (
    slug: string | undefined,
    sectionid: string | undefined,
    contentType?: string
  ) => {
  return useQuery({
    enabled: Boolean(slug),
    queryKey: ['course-sections', sectionid, contentType],
    queryFn: () => api.get('/section', { 
      params: { slug, sectionid, contentType }
    })
  })
}

export const useCreateSection = () => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const [
    sectionsuccessfullyCreatedT,
    youCanNowStartAddingMoreLecturesAndContentsToThisCourseT,
  ] = [
    'sectionsuccessfullyCreated',
    'youCanNowStartAddingMoreLecturesAndContentsToThisCourse',
  ].map(key => get(t('toast'), key, ''))
  const queryClient = useQueryClient()

  type Payload = { 
    name: string,
    courseId: string
  }

  return useMutation({
    mutationFn: (payload: Payload) => api.post('/section', payload),
    onSuccess: () => {
      queryClient.invalidateQueries(['course-sections'])
      toast({
        title: `${sectionsuccessfullyCreatedT}`,
        description: `${youCanNowStartAddingMoreLecturesAndContentsToThisCourseT}`,
      })
    }
  })
}

export const useDeleteSection = () => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const [
    sectionSuccessfullyDeletedT
  ] = [
    'sectionSuccessfullyDeleted'
  ].map(key => get(t('toast'), key, ''))
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id: string) => api.delete(`/section/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['course-sections'])
      toast({
        title: sectionSuccessfullyDeletedT
      })
    }
  })
}

export const useUpdateSection = () => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const [
    sectionSuccessfullyUpdatedT
  ] = [
    'sectionSuccessfullyUpdated'
  ].map(key => get(t('toast'), key, ''))
  type Payload = SectionFormData & { sectionId: string }

  return useMutation({
    mutationFn: (payload: Payload) => api.put(`/section/${payload.sectionId}`, omit(payload, 'sectionId')),
    onSuccess: () => {
      toast({ title: `${sectionSuccessfullyUpdatedT}` })
    }
  })
}

export const useUpdateAllSection = () => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const [
    sectionOrderSuccessfullyUpdatedT
  ] = [
    'sectionOrderSuccessfullyUpdated'
  ].map(key => get(t('toast'), key, ''))
  type Payload = { sections: string[] }

  return useMutation({
    mutationFn: (payload: Payload) => api.put(`/section`, payload),
    onSuccess: () => {
      toast({ title: `${sectionOrderSuccessfullyUpdatedT}` })
    }
  })
}

export const useAddSectionLecture = () => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const [
    lectureAddedSuccessfullyT
  ] = [
    'lectureAddedSuccessfully'
  ].map(key => get(t('toast'), key, ''))
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id: string) => api.post(`/section/${id}/add-lecture`),
    onSuccess: () => {
      toast({ title: `${lectureAddedSuccessfullyT}` })
      queryClient.invalidateQueries(['course-sections'])
    }
  })
}