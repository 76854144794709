import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardTitle } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import { Button } from '@/components/ui/button';
import { OnlineCourse } from '@/types/course';
import { get, lowerCase } from 'lodash';
import { useEnrollUserToCourse } from '@/hooks/course';
import { useTranslation } from 'react-i18next';

type Props = {
  data: OnlineCourse & {
    course_progress: number | null
    enrolled_by_user: boolean
  };
  cardType?: string;
};

export const CourseEnrolledProgressCard: FC<Props> = ({ data, cardType = "full" }) => {

  const navigate = useNavigate()
  const { t } = useTranslation();
  const enrollUserToCourse = useEnrollUserToCourse();
  const { status, course_progress } = data;

  const { slug, course_image, title, description, categories, level, _id } = data;
  
  const [
    startLearningT,
    continueLearningT,
    previewLearningT,
    enrollT,
    enrollingT,
  ] = [
    "startLearning",
    "continueLearning",
    "previewLearning",
    "enroll",
    "enrolling",
  ].map(key => get(t('introCard'), key, ''))

  const [statusActiveT, statusDraftT] = [
    'statusActive',
    'statusDraft'
  ].map(key => get(t('courseModalT'), key, ''))

  function handleClickCTA() {
    if (status === 'active' && !data.enrolled_by_user) {
      enrollUserToCourse.mutate({ id: _id })
      return 
    }

    if (data.enrolled_by_user && status === 'active') {
      navigate(`/${slug}`)
      return
    }
  
    if (status === 'draft') {
      navigate(`/info/${slug}`)
      return
    }
    
    alert('Invalid CTA')
  }

  const getStatusLabel = (status: string) => {
    switch(status){
      case 'active':
        return statusActiveT
      case 'draft':
        return statusDraftT
      default:
        return status
    }
  }

  const buttonLabel = useMemo(() => {
    let label: string = previewLearningT

    if (status === 'draft')
      return label

    if (data.enrolled_by_user && data.course_progress === 0)
      label = startLearningT

    if (data.enrolled_by_user && data.course_progress !== 0)
      label = continueLearningT

    if (!data.enrolled_by_user && !enrollUserToCourse.isLoading)
      label = enrollT

    if (!data.enrolled_by_user && enrollUserToCourse.isLoading)
      label = enrollingT
    
    return label
    //eslint-disable-next-line
  }, [data.enrolled_by_user, enrollUserToCourse.isLoading])

  return (
    <Card className={`
        ${cardType === 'full' && 'xl:w-5/6 w-full h-[235px] rounded-9 border-gray-500 border-solid border-[1px] overlflow-hidden rounded-lg flex bg-ol-primary overflow-hidden'} 
        ${cardType === 'compact' && 'w-[210px] grow xl:w-[225px] xl:flex-initial rounded-md overflow-hidden border-gray-500 border-solid border-[1px] flex flex-col bg-ol-primary group'} 
        ${(data.enrolled_by_user || buttonLabel === 'Preview') && 'cursor-pointer'}
        `}
      onClick={(e) => {
        e.stopPropagation();
        if(!data.enrolled_by_user && buttonLabel !== 'Preview'){
          return
        }
        handleClickCTA();
        // if (status === "draft") navigate(`/info/${slug}`)
        // else if (status === 'active') return
        // else navigate(`/${slug}`)
      }}
        >
      <div className={`relative 
          ${cardType === 'full' && 'h-full w-[820px]'} 
          ${cardType === 'compact' && 'h-[180px] md:h-[150px] w-full'} 
        `}>
        <p className="absolute top-2 left-2 px-2 text-sm font-semibold rounded shadow z-9" style={{ backgroundColor: 'var(--bg-color)' }}>
          {level?.name}
        </p>
        <img
          className="object-fill md:object-cover object-top h-full w-full"
          src={course_image?.url ?? "/default-course-thumbnail.png"}
          alt={course_image?.name ?? "Default Course Cover"}
          loading="lazy"
        />
      </div>
      <CardContent className="w-full p-4 flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center">
            <div className="flex items-center justify-start gap-x-2">
              <CardTitle 
                className={`font-bold text-xl text-white line-clamp-2 lg:line-clamp-none ${cardType === 'compact' && 'truncate'}`}>
                {title} {status === 'draft' && '- (Draft)'}
              </CardTitle>
            </div>
            {cardType === "full" && (
              <p className={`
                ${lowerCase(status) === 'active' ? 'bg-ol-success' : 'bg-orange-500'}
                px-2 text-xs rounded w-fit mb-2 text-white font-bold`}>{getStatusLabel(status)}</p>
            )}
          </div>
          {/* <div className="pt-2 pb-1 text-sm text-white capitalize">{status ?? 'started'} {completedDate ? `- ${format(completedDate, 'PPP')}` : null}</div> */}
          <p className={`
            ${cardType === 'full' && 'text-[#AAAAAA] font-semibold line-clamp-2 lg:line-clamp-2'}
            ${cardType === 'compact' && 'font-normal pt-1 text-xs text-white line-clamp-2'}
          `}>
            {description}
          </p>
          {cardType === "full" && (
            <div className="flex items-center border-t pt-2 mt-2 border-gray-500 gap-x-2">
              {categories.map((cat) => <p className="bg-ol-warning shadow text-white text-sm font-medium rounded px-2" key={cat._id}>{cat.name}</p> )}
            </div>
          )}
        </div>
        <div>
          <div className="flex justify-start items-center">
            {(typeof course_progress === 'number' && course_progress !== 0) && <>
              <Progress
                value={course_progress}
                className="w-[70%] bg-[#5D5D5D] mr-5"
              />
              <div className="text-sm text-white">{`${get(
                data,
                'course_progress'
              )}%`}</div>
            </>
            }

          </div>
          <Button
            disabled={enrollUserToCourse.isLoading}
            className={`w-[156px] h-[37px] font-semibold uppercase text-white
                ${course_progress === 0 || (course_progress === null && status !== 'draft')
                ? 'bg-ol-info'
                : (status === "started" || status === "completed"
                  ? 'bg-ol-success'
                  : (status === "draft" ? 'bg-[#D97706]' : 'bg-ol-info'))}
              mt-2`}
            onClick={handleClickCTA}>
            {buttonLabel}
            {/* {
              course_progress === 0 || (course_progress === null && (status !== 'draft' && status !== 'active'))
                ? startLearningT
                : (status === "started" || status === "completed") && course_progress !== null
                  ? continueLearningT
                  : status === "draft"
                    ? previewLearningT
                    : status === 'active'
                    && enrollT
            } */}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
